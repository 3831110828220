<template>
  <el-form
    :model="ruleForm"
    status-icon
    :rules="rules"
    ref="ruleForm"
    class="demo-ruleForm"
  >
    <el-form-item
      label="Describa la situación:"
      id="situation"
      class="w-100"
      prop="situation"
    >
      <!-- Describa la situación -->
      <el-input
        type="textarea"
        :rows="3"
        placeholder="Describa la situación"
        v-model="ruleForm.situation"
        maxlength="800"
        show-word-limit
      ></el-input>
    </el-form-item>
    <el-form-item
      label="¿Cuál es su sugerencia o preocupación?"
      class="w-100"
      id="suggestion_text"
      prop="suggestion_text"
    >
      <!-- Sugerencia o preocupación -->
      <el-input
        placeholder="Escriba su sugerencia"
        v-model="ruleForm.suggestion_text"
      ></el-input>
    </el-form-item>
    <el-form-item
      id="impact"
      label="¿A qué área o personas impactan?"
      class="w-100"
      prop="impact"
    >
      <!-- A qué área o personas impactan -->
      <el-input
        placeholder="¿A qué área o personas impactan?"
        v-model="ruleForm.impact"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="¿Qué espera generar con esta sugerencia?"
      class="w-100"
      id="expected_result"
      prop="expected_result"
    >
      <!-- Qué espera generar con esta sugerencia -->
      <el-input
        placeholder="¿Qué espera generar con esta sugerencia?"
        v-model="ruleForm.expected_result"
      ></el-input>
    </el-form-item>
    <hr />
    <el-row type="flex" justify="space-between">
      <el-button type="info" plain @click="next(lastStep)">Volver</el-button>
      <el-button
        v-if="!waiting"
        type="primary"
        @click.prevent="submitForm('ruleForm')"
        >Siguiente</el-button
      >
      <el-button v-if="waiting" type="primary" :loading="true"
        >Guardando...</el-button
      >
    </el-row>
  </el-form>
</template>

<script>
export default {
  props: [
    "next",
    "setId",
    "lastStep",
    "setStepData",
    "complaint_id",
    "oldData",
    "complaint",
  ],
  data() {
    return {
      waiting: false,
      error: null,
      ruleForm: {
        complaint_id: this.$props.complaint_id,
        situation: this.$props.oldData.situation
          ? this.$props.oldData.situation
          : "",
        suggestion_text: this.$props.oldData.suggestion_text
          ? this.$props.oldData.suggestion_text
          : "",
        impact: this.$props.oldData.impact ? this.$props.oldData.impact : "",
        expected_result: this.$props.oldData.expected_result
          ? this.$props.oldData.expected_result
          : "",
      },
      rules: {
        situation: [
          {
            required: true,
            message: "Debe describir la situación",
            trigger: "blur",
          },
          {
            min: 10,
            message: "Por favor informar más detalles de la situación",
            trigger: "blur",
          },
        ],
        suggestion_text: [
          {
            required: true,
            message: "Escriba su sugerencia o preocupación",
            trigger: "blur",
          },
          {
            min: 10,
            max: 200,
            message: "Por favor informar más detalles de la sugerencia",
            trigger: "blur",
          },
        ],
        impact: [
          {
            required: true,
            message: "Indique a qué área o personas impactan",
            trigger: "blur",
          },
          {
            min: 10,
            max: 200,
            message: "Por favor indicar más detalles",
            trigger: "blur",
          },
        ],
        expected_result: [
          {
            required: true,
            message: "Indique el resultado esperado",
            trigger: "blur",
          },
          {
            min: 10,
            max: 200,
            message:
              "Por favor indicar más detalles sobre el resultado esperado",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid, rules) => {
        if (valid) {
          this.waiting = true;
          const url = `${window.location.origin}/complaint/postdata2a`;
          axios
            .post(url, { ...this.ruleForm, complaint: this.complaint })
            .then((res) => {
              this.waiting = false;
              if (res.data.success) {
                this.$props.setId(res.data.id, res.data.complaint);
                this.$props.setStepData(this.ruleForm);
                this.$props.next(3);
              }
            })
            .catch((e) => {
              this.waiting = false;
            });
        } else {
          this.goToError(rules);
        }
      });
    },
  },
};
</script>