<template>
    <div class="container">
      <h3 class="text-center" id="init_form">Línea ética</h3>
      <div class="stepwizard">
        <div class="stepwizard-row setup-panel">
          <div class="stepwizard-step col-4">
            <h5><span id="step-1" :class="getClass(step, 1)">Paso 1</span></h5>
            <p class="d-none d-sm-block"><small>Información inicial</small></p>
          </div>
          <div class="stepwizard-step col-4">
            <h5><span id="step-2" :class="getClass(step, 2)">Paso 2</span></h5>
            <p class="d-none d-sm-block"><small>Detalle de denuncia</small></p>
          </div>
          <div class="stepwizard-step col-4">
            <h5><span id="step-3" :class="getClass(step, 3)">Paso 3</span></h5>
            <p class="d-none d-sm-block"><small>Denuncia finalizado</small></p>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props: ['step'],
  methods: {
    getClass(actual, value) {
      if (actual == value) {
        return "badge badge-success";
      } // paso activo
      else {
        if((actual == 'a' || actual == 'b') && value == 2){
          return "badge badge-success";
        }
        if(actual == 4 && value == 3){
          return "badge badge-success";
        }
        return "badge badge-secondary";
      } // paso inactivo
    },

  }
}
</script>
