<template>
  <div class="bg-light mt-3 px-md-4 px-3 py-2">
    <div>
      <div>
        <h5>Nuevo tipo de personas</h5>
      </div>
      <div v-if="errors.length">
        <el-alert type="error">
          <ul class="mb-0">
            <li v-for="(error, index) in errors" v-bind:key="index">
              {{ error.message }}
            </li>
          </ul>
        </el-alert>
      </div>
      <div id="form_output"></div>
      <el-form
        class="col w-100 p-0"
        id="local_form"
        :rules="rules"
        :model="local"
        ref="ruleForm"
        @submit.prevent="submitForm('ruleForm')"
        status-icon
      >
        <div class="row w-100 d-flex justify-content-between mx-0">
          <el-form-item class="col pl-0" prop="name" id="name">
            <el-input
              class="w-100"
              v-model="local.name"
              placeholder="Ingrese el tipo de persona"
            />
          </el-form-item>
          <div>
            <button
              size="small"
              @click.prevent="submitForm('ruleForm')"
              class="btn btn-info text-white"
            >
              {{ isEdition ? "Actualizar" : "Guardar" }}
            </button>
          </div>
        </div>
      </el-form>
    </div>
    <!-- --------------------------------------------------------- -->
    <div class="row w-100 mx-0">
      <el-table
        :data="peopleList"
        style="width: 100%; height: 100%"
        max-height="500"
        v-loading="waiting"
        :default-sort="{ prop: 'id', order: 'ascending' }"
      >
        <el-table-column prop="id" label="Cód" width="90" sortable>
        </el-table-column>
        <el-table-column prop="name" min-width="250" label="Nombre" sortable>
        </el-table-column>
        <el-table-column
          label="Estado"
          min-width="200"
          :filters="[
            { text: 'Activo', value: 1 },
            { text: 'Inactivo', value: 0 },
          ]"
          :filter-method="filterState"
        >
          <template slot-scope="props">
            <el-switch
              class="align-items-center switch-compact switch"
              @change="editPersonState(props.row, props.$index)"
              :value="props.row.active == 1"
              active-text="Activo"
              inactive-text="Inactivo"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column class-name="item-center" label="Acciones" min-width="100" fixed="right">
          <template slot-scope="props">
            <el-button
            size="small"
              @click="edit(props.row, props.$index)"
              :type="isEdition && props.$index == index ? 'warning' : 'primary'"
              :icon="
                isEdition && props.$index == index
                  ? 'el-icon-close'
                  : 'el-icon-edit'
              "
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["types"],
  data() {
    return {
      local: {
        id: "",
        name: "",
        active: true,
      },
      peopleList: this.types,
      isEdition: false,
      index: null,
      errors: [],
      waiting: false,
      rules: {
        name: [
          {
            required: true,
            message: "Debe escribir el tipo de persona",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid, rules) => {
        console.log(valid, rules);
        if (valid) {
          this.submit();
        } else {
          this.goToError(rules);
        }
      });
    },
    filterState(value, row) {
      return row.active == value;
    },
    editPersonState(row, index) {
      const { id, name, active } = row;
      this.local = {
        id,
        name,
        active: active == 1 ? 0 : 1,
      };
      this.index = index;
      this.submitEdit();
    },
    edit(row, index) {
      const { id, name, active } = row;
      if (this.isEdition) {
        this.isEdition = false;
        this.clearForm();
        this.index = null;
      } else {
        this.isEdition = true;
        this.local = {
          id,
          name,
          active,
        };
        this.index = index;
      }
    },
    submit() {
      this.errors = [];
      if (this.isEdition) {
        this.submitEdit();
      } else {
        this.submitCreate();
      }
    },
    submitCreate() {
      this.waiting = true;
      const url = `${window.location.origin}/people`;
      axios
        .post(url, this.local)
        .then((res) => {
          this.waiting = false;
          this.peopleList.push(res.data.person);
          this.clearForm();
          this.$message({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          this.waiting = false;
          if (this.truthty(error.response.data)) {
            this.errors = [];
            if (this.truthty(error.response.data.server)) {
              this.$message({
                message: error.response.data.message,
                type: "error",
              });
            } else {
              error.response.data.forEach((row) =>
                this.errors.push({ message: row })
              );
            }
          }
        });
    },
    submitEdit() {
      this.waiting = true;
      const url = `${window.location.origin}/people/${this.local.id}`;
      axios
        .put(url, this.local)
        .then((res) => {
          this.waiting = false;
          const peopleListAux = this.peopleList.map((row, index) => {
            if (index == this.index) {
              return this.local;
            } else return row;
          });
          this.peopleList = this.copy(peopleListAux);
          this.isEdition = false;
          this.clearForm();
          this.$message({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          this.waiting = false;
          if (this.truthty(error.response.data)) {
            this.errors = [];
            if (this.truthty(error.response.data.server)) {
              this.$message({
                message: error.response.data.message,
                type: "error",
              });
            } else {
              error.response.data.forEach((row) =>
                this.errors.push({ message: row })
              );
            }
          }
          // this.error = 1;
        });
    },
    clearForm() {
      this.local = {
        id: "",
        name: "",
      };
      this.$refs["ruleForm"].resetFields();
    },
  },
  mounted() {},
};
</script>
