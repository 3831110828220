var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 pl-2"},[_c('h5',{staticClass:"text-center"},[_vm._v("\n    Resumen de denuncias recibidas período\n    "),_c('strong',[_vm._v(_vm._s(`${_vm.startText} - ${_vm.endText}`))])]),_vm._v(" "),_c('el-collapse',{staticClass:"w-100 border-bottom-0 mb-3",model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_c('el-collapse-item',{staticClass:"div-filters",attrs:{"name":"1"}},[_c('template',{slot:"title"},[_c('h5',{staticClass:"pl-3"},[_c('i',{staticClass:"el-icon-plus mr-2"}),_vm._v("Ver fitros")])]),_vm._v(" "),_c('div',{staticClass:"row mx-2 mb-4"},[_c('div',{staticClass:"col-md-5 pl-0 pb-3"},[_c('el-select',{staticClass:"w-100",attrs:{"disable":"","multiple":"","filterable":"","placeholder":"Filtra por país"},on:{"change":(value) => {
                _vm.selectAll(value, 'country', 'countries');
              }},model:{value:(_vm.payload.country),callback:function ($$v) {_vm.$set(_vm.payload, "country", $$v)},expression:"payload.country"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.countries),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})})],2),_vm._v(" "),_c('el-select',{staticClass:"mt-3 w-100",attrs:{"disable":"","multiple":"","filterable":"","placeholder":"Filtra por estado de incidente"},on:{"change":(value) => {
                _vm.selectAll(value, 'state', 'complaintState');
              }},model:{value:(_vm.payload.state),callback:function ($$v) {_vm.$set(_vm.payload, "state", $$v)},expression:"payload.state"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.complaintState),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-md-5 pl-0 pb-3"},[_c('el-select',{staticClass:"w-100",attrs:{"disable":"","multiple":"","filterable":"","placeholder":"Filtra por tipo de denuncia"},on:{"change":(value) => {
                _vm.selectAll(value, 'anonymous', 'optionsAnonimus');
              }},model:{value:(_vm.payload.anonymous),callback:function ($$v) {_vm.$set(_vm.payload, "anonymous", $$v)},expression:"payload.anonymous"}},[_c('el-option',{attrs:{"label":"Todos","value":"all"}}),_vm._v(" "),_vm._l((_vm.optionsAnonimus),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})})],2),_vm._v(" "),_c('el-date-picker',{staticClass:"mt-3 w-100",attrs:{"type":"monthrange","align":"right","unlink-panels":"","range-separator":"-","start-placeholder":"Inicio","end-placeholder":"Fin","picker-options":_vm.pickerOptions},model:{value:(_vm.monthRange),callback:function ($$v) {_vm.monthRange=$$v},expression:"monthRange"}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-2 d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"btn btn-info text-white mr-2",on:{"click":_vm.filter}},[_vm._v("\n            Filtrar\n          ")]),_vm._v(" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Descargar","placement":"top"}},[_c('el-button',{staticClass:"btn-big-icon h-100",attrs:{"type":"success","plain":"","icon":"big-icon el-icon-download"},on:{"click":_vm.downloadComplaints}})],1),_vm._v(" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"limpiar","placement":"top"}},[_c('el-button',{staticClass:"btn-big-icon h-100",attrs:{"plain":"","icon":"big-icon el-icon-brush"},on:{"click":_vm.clean}})],1)],1)])],2)],1),_vm._v(" "),_c('div',{staticClass:"row px-3 my-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"col mb-5"},[_c('pie-chart',{attrs:{"chart-data":_vm.dataAnonymous,"options":{
            ..._vm.optionsBasic,
            onClick: _vm.redirectToComplaint,
            name: 'anonymous',
            title: {
              display: true,
              text: 'Tipos de denuncia',
              fontSize: 16,
              fontColor: '#212529',
            },
            legend: {
              position: 'right',
            },
          },"styles":_vm.myStyles}})],1),_vm._v(" "),_c('div',{staticClass:"col mb-5"},[_c('bar-chart',{attrs:{"styles":_vm.myStylesCountry,"options":{
            ..._vm.options,
            onClick: _vm.redirectToComplaint,
            name: 'country',
            scales: {
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    stepSize: 3,
                  },
                },
              ],
            },
            title: {
              display: true,
              text: 'Países de incidentes',
              fontSize: 16,
              fontColor: '#212529',
            },
          },"chart-data":_vm.dataIncidentsCountry}})],1)]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('div',{staticClass:"col mb-5"},[_c('pie-chart',{attrs:{"chart-data":_vm.dataIncidentState,"options":{
            ..._vm.optionsBasic,
            onClick: _vm.redirectToComplaint,
            name: 'state',
            title: {
              display: true,
              text: 'Estados de incidentes',
              fontSize: 16,
              fontColor: '#212529',
            },
            legend: {
              position: 'right',
            },
          },"styles":_vm.myStyles}})],1),_vm._v(" "),_c('div',{staticClass:"col mb-5"},[_c('pie-chart',{attrs:{"chart-data":_vm.dataInvestigator,"options":{
            ..._vm.optionsBasic,
            onClick: _vm.redirectToComplaint,
            name: 'investigator',
            title: {
              display: true,
              text: 'Denuncias por investigador',
              fontSize: 16,
              fontColor: '#212529',
            },
            legend: {
              position: 'right',
            },
          },"styles":_vm.myStyles}})],1)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col my-2"},[_c('bar-chart',{attrs:{"styles":_vm.myStilyType,"options":{
          ..._vm.options,
          onClick: _vm.redirectToComplaint,
          name: 'type',
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  stepSize: 3,
                },
              },
            ],
          },
          title: {
            display: true,
            text: 'Tipos de incidentes',
            fontSize: 16,
            fontColor: '#212529',
          },
        },"chart-data":_vm.dataIncidentType}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 my-1"},[_c('hr'),_vm._v(" "),_c('h5',{staticClass:"text-left"},[_vm._v("\n        Cantidad de incidentes presentados por mes en el período indicado\n      ")]),_vm._v(" "),_c('div',{staticClass:"justify-content-center text-center"},[_c('line-chart',{attrs:{"chart-data":_vm.datacollectionMonth,"options":_vm.options,"styles":_vm.myStyles}})],1)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 my-1"},[_c('hr'),_vm._v(" "),_c('h5',{staticClass:"text-left"},[_vm._v("\n        Cantidad Total de incidentes presentados en los últimos 12 meses\n      ")]),_vm._v(" "),_c('div',{staticClass:"justify-content-center text-center"},[_c('line-chart',{attrs:{"chart-data":_vm.datacollection,"options":_vm.options,"styles":_vm.myStyles}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }