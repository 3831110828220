<template>
  <div class="py-3">
    <el-alert v-if="isError" type="error" :title="errorMessage" />
    <el-alert v-if="isSuccess" type="success" :title="successMessage" />
    <div class="w-100 d-flex justify-content-center mb-3">
      <el-switch
        class="align-items-center switch-compact mt-3"
        :value="comprimido"
        active-text="Extenso"
        inactive-text="Compacto"
        @change="openCollapse"
      >
      </el-switch>
    </div>
    <div>
      <el-collapse class="w-100 border-bottom-0 mb-3" v-model="activeNames">
        <el-collapse-item name="1" class="div-filters">
          <template slot="title"
            ><h5 class="pl-3"><i class="el-icon-plus mr-2" />Ver filtros</h5>
          </template>

          <el-form
            id="filters-form"
            :model="filters"
            @submit.prevent="getComplaints"
          >
            <div class="row mx-2">
              <div class="col-md-2 pl-0 col-sm-6">
                <el-form-item class="w-100">
                  <el-input
                    placeholder="Busqueda por codigo"
                    v-model="filters.code"
                  />
                </el-form-item>
              </div>
              <div class="col-md-3 pl-0 col-sm-6">
                <el-form-item class="w-100">
                  <el-input
                    placeholder="Busqueda por texto"
                    v-model="filters.text"
                  />
                </el-form-item>
              </div>
              <div class="col-md-4 pl-0 col-sm-6">
                <el-form-item class="w-100">
                  <el-date-picker
                    class="w-100"
                    v-model="monthRange"
                    type="monthrange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    start-placeholder="Inicio"
                    end-placeholder="Fin"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="col-md-3 pl-0 col-sm-6">
                <el-form-item class="w-100">
                  <el-select
                    v-model="filters.countries"
                    placeholder="Por país"
                    multiple
                    filterable
                    class="w-100"
                    @change="
                      (value) => {
                        selectAll(value, 'countries');
                      }
                    "
                  >
                    <el-option label="Todos" value="all"></el-option>
                    <el-option
                      v-for="country in countries"
                      :key="country.id"
                      :label="country.name"
                      :value="country.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <div class="row mx-1 mb-3">
              <div class="col-md-3 pl-0 col-sm-6">
                <el-form-item class="w-100">
                  <el-select
                    v-model="filters.anonymous"
                    disable
                    multiple
                    filterable
                    placeholder="Por tipo de denuncia"
                    class="w-100"
                    @change="
                      (value) => {
                        selectAll(value, 'anonymous');
                      }
                    "
                  >
                    <el-option label="Todos" value="all"></el-option>
                    <el-option
                      v-for="item in optionsAnonymous"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-md-3 pl-0 col-sm-6">
                <el-form-item class="w-100">
                  <el-select
                    v-model="filters.states"
                    multiple
                    filterable
                    placeholder="Por estado de incidente"
                    class="w-100"
                    @change="
                      (value) => {
                        selectAll(value, 'states');
                      }
                    "
                  >
                    <el-option label="Todos" value="all"></el-option>
                    <el-option
                      v-for="state in states"
                      :key="state.id"
                      :label="state.name"
                      :value="state.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-md-3 pl-0 col-sm-6">
                <el-form-item class="w-100">
                  <el-select
                    v-model="filters.types"
                    placeholder="Por tipo de incidente"
                    multiple
                    filterable
                    class="w-100"
                    @change="
                      (value) => {
                        selectAll(value, 'types');
                      }
                    "
                  >
                    <el-option label="Todos" value="all"></el-option>
                    <el-option
                      v-for="type in types"
                      :key="type.id"
                      :label="type.type"
                      :value="type.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="col-md-3 text-center col-sm-6">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Ordenar según días transcurridos"
                  placement="top"
                >
                  <div slot="content" class="text-center">
                    Ordenar según días transcurridos<br />{{ text }}
                  </div>
                  <el-button
                    class="btn-big-icon h-100"
                    type="info"
                    plain
                    @click="changeOrder"
                    :icon="`big-icon ${icon}`"
                  />
                </el-tooltip>

                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Filtrar"
                  placement="top"
                >
                  <el-button
                    class="btn-big-icon h-100"
                    type="primary"
                    plain
                    @click="getComplaints"
                    icon="big-icon el-icon-set-up"
                  />
                </el-tooltip>

                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Descargar"
                  placement="top"
                >
                  <el-button
                    class="btn-big-icon h-100"
                    type="success"
                    plain
                    @click="downloadComplaints"
                    icon="big-icon el-icon-download"
                  />
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="limpiar"
                  placement="top"
                >
                  <el-button
                    class="btn-big-icon h-100"
                    plain
                    @click="clean"
                    icon="big-icon el-icon-brush"
                  />
                </el-tooltip>
              </div>
            </div>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="col-md-12 mt-3 px-0" v-loading="loading">
      <complaint-card
        v-for="complaint in complaints"
        :complaint="complaint"
        :key="complaint.id"
        :comprimido="comprimido"
        :dialogVisible="() => handleDialogVisible(complaint.id)"
        :dialogInvVisible="() => handleDialogInvVisible(complaint)"
        :auth="auth"
        :users="users"
        :getComplaints="getComplaints"
        :activities="activities"
      />
      <h3 class="text-center" v-show="complaints.length === 0">Sin datos.</h3>
      <div class="row d-flex flex-row justify-content-center">
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="perPage"
          layout="prev, pager, next"
          :total="totalPages"
          hide-on-single-page
          @current-change="getComplaints"
        >
        </el-pagination>
      </div>
    </div>
    <!-- AGREGAR COMENTARIOS -->
    <el-dialog class="updateDialog" :visible.sync="dialogVisible">
      <h5 slot="title">
        Actualizar información de la denuncia
        {{ updateComplaintForm.id }}
      </h5>

      <el-form :model="updateComplaintForm">
        <el-form-item label="Agregar Comentario">
          <el-input
            v-model="updateComplaintForm.comment"
            class="w-100"
          ></el-input>
        </el-form-item>

        <el-form-item label="Está dirigido a">
          <el-radio v-model="updateComplaintForm.is_admin" :label="false"
            >El denunciante</el-radio
          >
          <el-radio v-model="updateComplaintForm.is_admin" :label="true"
            >La administración</el-radio
          >
        </el-form-item>
        <el-form-item label="Actualizar estado (Opcional)">
          <el-select
            v-model="updateComplaintForm.state"
            placeholder="Seleccione un nuevo estado"
            filterable
          >
            <el-option
              v-for="state in states"
              :key="state.id"
              :label="state.name"
              :value="state.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="!updateComplaintForm.suggestion"
          label="Reclasificar denuncia (Opcional)"
        >
          <el-select
            v-model="updateComplaintForm.type"
            placeholder="Seleccione un tipo de denuncia"
            filterable
          >
            <el-option
              v-for="type in types"
              :key="type.id"
              :label="type.type"
              :value="type.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <!-- :on-change="handleChangePrivacy"
              :on-remove="handleRemovePrivacy"
              :on-preview="handlePreviewPrivacy"
							:on-exceed="handleExceedPrivacy"
              :file-list="settingsPage.privacy" -->
          <el-upload
            class="upload-demo"
            action="#"
            :auto-upload="false"
            :multiple="false"
            :limit="1"
            accept="application/pdf"
            :on-change="handleChangeFile"
            :on-remove="handleRemoveFile"
            :on-exceed="handleExceedFile"
            ref="inputFileComent"
          >
            <el-button size="small" type="primary"
              >Click para subir archivo</el-button
            >
            <div slot="tip" class="el-upload__tip">
              Tamaño máximo de archivo 10MB en formato PDF
            </div>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDialogVisible()">Volver</el-button>
        <el-button
          type="primary"
          @click="() => handleSaveStateLog()"
          :disabled="
            updateComplaintForm.state === updateComplaintForm.oldState &&
            updateComplaintForm.type === updateComplaintForm.oldType &&
            (updateComplaintForm.comment === '' ||
              updateComplaintForm.comment.length < 10)
          "
          :loading="loadingUpdateComplaint"
        >
          Guardar</el-button
        >
      </span>
    </el-dialog>
    <el-dialog class="updateDialog" :visible.sync="dialogInvVisible">
      <h5 slot="title">
        Asignación de caso
        {{ caseAsignament.complaint_id }}
      </h5>
      <el-form
        id="local_form"
        ref="ruleForm"
        :rules="rules"
        :model="caseAsignament"
        @submit.prevent="submitForm('ruleForm')"
      >
        <el-form-item label="Asignar a:" prop="user_id" id="user_id">
          <!-- v-model="investigator" -->
          <el-select v-model="caseAsignament.user_id" class="w-100" filterable>
            <el-option
              v-for="user in users"
              :key="user.id"
              :value="user.id"
              :label="user.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="Días para realizar la acción:"
          prop="daysSolve"
          id="daysSolve"
        >
          <!-- v-model="days" -->
          <el-input-number
            :min="1"
            class="w-100"
            v-model="caseAsignament.daysSolve"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="Actividad"
          prop="activity_types_id"
          id="activity_types_id"
        >
          <el-select
            v-model="caseAsignament.activity_types_id"
            class="w-100"
            filterable
            clearable
          >
            <el-option
              v-for="activity in activities"
              :key="activity.id"
              :value="activity.id"
              :label="activity.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="description"
          id="description"
          label="Instrucciones de asignación:"
          class="d-flex align-items-start"
        >
          <el-input
            type="textarea"
            :rows="5"
            class="w-100"
            placeholder="Ingrese las instrucciones"
            v-model="caseAsignament.description"
          >
            <!-- v-model="textarea" -->
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDialogInvVisible()">Volver</el-button>
        <el-button
          type="primary"
          @click.prevent="submitForm('ruleForm')"
          :loading="loadingUpdateComplaint"
          >Asignar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: [
    "countries",
    "states",
    "types",
    "preFilters",
    "users",
    "auth",
    "activities",
  ],
  data() {
    return {
      activeNames: [],
      complaints: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      icon: "el-icon-d-caret",
      text: "default",
      investigator: null,
      caseAsignament: {
        id: null,
        description: "",
        daysSolve: 1,
        user_id: null,
        complaint_id: null,
        activity_types_id: null,
      },
      rules: {
        description: [
          {
            required: true,
            message: "Debe agregar la descripción",
            trigger: ["change", "blur"],
          },
        ],
        user_id: [
          {
            required: true,
            message: "Debe escoger al usuario asignado",
            trigger: ["change", "blur"],
          },
        ],
        activity_types_id: [
          {
            required: true,
            message: "Debe escoger la actividad a asignar",
            trigger: ["change", "blur"],
          },
        ],
        daysSolve: [
          {
            required: true,
            message: "Debe agregar días asignados",
            trigger: ["change", "blur"],
          },
        ],
      },
      filters: {
        anonymous: [],
        code: "",
        countries: [],
        monthRange: [],
        states: [],
        text: "",
        types: [],
        sortDays: "",
      },
      oldFilters: {
        anonymous: [],
        code: "",
        countries: [],
        monthRange: [],
        states: [],
        text: "",
        types: [],
        sortDays: "",
      },
      changeFilter: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "Este mes",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "Últimos 6 meses",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Últimos 12 meses",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setFullYear(start.getFullYear() - 1);
              start.setMonth(start.getMonth() + 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Este año",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      monthRange: [],
      updateComplaintForm: {
        comment: "",
        oldState: "",
        state: "",
        oldType: "",
        suggestion: false,
        type: "",
        id: 0,
        file: null,
        is_admin: true,
      },
      dialogVisible: false,
      dialogInvVisible: false,
      loading: false,
      loadingUpdateComplaint: false,
      isError: false,
      isSuccess: false,
      successMessage: "",
      errorMessage: "",
      comprimido: false,
      optionsAnonymous: [
        { value: 1, label: "Anónimo" },
        { value: 0, label: "No anónimo" },
      ],
    };
  },
  methods: {
    onBeforeUpload(file) {
      console.log("archivo", file);
      const validTypes = ["application/pdf"];
      let isValid = validTypes.includes(file.type);

      const isLt10M = file.size / 10240 / 10240 < 10;

      if (!isValid) {
        this.$message.error("El archivo subido no cumple con el formato PDF");
      }
      if (!isLt10M) {
        this.$message.error("El archivo subido supera el tamaño maximo");
      }
      return isValid && isLt10M;
    },
    verifyRecapcha(response = null) {
      this.recapchaValue = response;
    },
    haveChangeFilter() {
      if (!_.isEqual(this.filters, this.oldFilters)) {
        this.changeFilter = true;
        this.oldFilters = this.copy(this.filters);
      }
    },
    getComplaints() {
      this.remplaceParam(this.oldFilters);
      this.remplaceParam(this.filters);
      this.haveChangeFilter();
      if (this.changeFilter) {
        this.currentPage = 1;
      }
      const url = `${window.location.origin}/complaints/paginate?page=${this.currentPage}`;
      this.isSuccess = false;
      this.changeFilter = false;
      this.isError = false;
      this.loading = true;
      this.filters.monthRange = this.monthRange;
      this.oldFilters.monthRange = this.monthRange;
      axios
        .post(url, this.filters)
        .then((response) => {
          this.loading = false;
          this.updateComplaintPagination(response.data);
        })
        .catch((error) => {
          this.errorMessage =
            "Error al aplicar filtro, por favor intente nuevamente";
          this.isError = true;
          this.loading = false;
        });
    },
    filterState(value, row) {
      return row.active == value;
    },
    updateComplaintList(data) {
      this.complaints = data;
    },
    updateComplaintPagination(response) {
      const { data, total } = response;
      this.updateComplaintList(data);
      this.totalPages = total;
      const filtersDiv = document.getElementById("filters-form");
      filtersDiv.scrollIntoView();
    },
    downloadComplaints() {
      const url = `${window.location.origin}/complaints/export`;
      this.isSuccess = false;
      this.isError = false;
      this.filters.monthRange = this.monthRange;
      axios
        .post(url, this.filters, { responseType: "blob" })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          const tst = moment().format("DD/MM/YYYY hh:mm:ss");
          link.href = url;
          link.setAttribute(
            "download",
            "Denuncias y recomendaciones " + tst + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          this.successMessage = "Se ha generado correctamente el excel.";
          this.isSuccess = true;
        })
        .catch((error) => {
          this.errorMessage =
            "Error al descargar excel, por favor intentar nuevamente o comunicarse con soporte.";
          this.isError = true;
        });
    },

    clearCaseAsignament() {
      this.caseAsignament = {
        id: null,
        description: "",
        daysSolve: 1,
        user_id: null,
        complaint_id: null,
      };
    },
    resetField(){
      this.$refs["ruleForm"].resetFields();
    },
    handleDialogInvVisible(complaint = null) {
      const newState = !this.dialogInvVisible;
      this.dialogInvVisible = newState;
      this.clearCaseAsignament();
      if (newState) {
        this.caseAsignament.complaint_id = complaint.id;
      }
    },
    handleDialogVisible(id = 0) {
      const newState = !this.dialogVisible;
      this.dialogVisible = newState;
      if (!newState) {
        this.updateComplaintForm = {
          comment: "",
          oldState: "",
          state: "",
          oldType: "",
          suggestion: false,
          file: null,
          type: "",
          id: 0,
          is_admin: true,
        };
      } else {
        const complaint = this.complaints.find((row) => row.id === id);
        this.updateComplaintForm = {
          comment: "",
          oldState: complaint.state_logs[0].complaint_state_id,
          state: complaint.state_logs[0].complaint_state_id,
          oldType: complaint.complaints_type_id,
          suggestion: complaint.suggestion,
          type: complaint.complaints_type_id,
          id,
          is_admin: true,
        };
      }
    },
    submitForm(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid, rules) => {
        console.log(valid, rules);
        if (valid) {
          this.handleSaveInv();
        } else {
          this.goToError(rules);
        }
      });
    },
    handleSaveInv() {
      let api;
      // if (this.truthty(this.caseAsignament.id)) {
      //   const url = `${window.location.origin}/caseAssignment/${this.caseAsignament.id}`;
      //   console.log(url);
      //   api = axios.put(url, this.caseAsignament);
      // } else {
      const url = `${window.location.origin}/caseAssignment`;
      // console.log(url);
      api = axios.post(url, this.caseAsignament);
      // }
      api
        .then((response) => {
          // console.log(response);
          if (response.status) {
            this.clearCaseAsignament();
            this.resetField();
            const newState = !this.dialogInvVisible;
            this.dialogInvVisible = newState;
            this.getComplaints();
            // console.log(response.data.message);
            this.$message({
              message: response.data.message,
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.error(error.response);
          this.$message({
            message: error.response.data.message,
            type: "error",
          });
        });
    },
    handleSaveStateLog() {
      const url = `${window.location.origin}/statelog/store`;
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.loadingUpdateComplaint = true;
      let formData = new FormData();
      formData.append("form", JSON.stringify(this.updateComplaintForm));
      formData.append("file", this.updateComplaintForm.file);
      axios
        .post(url, formData, config)
        .then((response) => {
          this.getComplaints();
        })
        .catch((error) => {
          this.errorMessage =
            "Error al registrar comentario, por favor intente nuevamente";
        });
      this.loadingUpdateComplaint = false;
      this.$refs.inputFileComent.clearFiles();
      this.updateComplaintForm = {
        comment: "",
        oldState: "",
        state: "",
        oldType: "",
        type: "",
        file: null,
        id: 0,
        is_admin: true,
      };
      const newState = !this.dialogVisible;
      this.dialogVisible = newState;
    },
    openCollapse() {
      const comp = !this.comprimido;
      this.comprimido = comp;
    },
    changeOrder() {
      const {
        icon,
        text,
        filters: { sortDays },
      } = this;
      const sortValues = {
        "": "sortBy",
        sortBy: "sortByDesc",
        sortByDesc: "",
      };
      const iconValues = {
        "el-icon-d-caret": "el-icon-caret-top",
        "el-icon-caret-top": "el-icon-caret-bottom",
        "el-icon-caret-bottom": "el-icon-d-caret",
      };
      const textValues = {
        default: "ascendente",
        ascendente: "descendente",
        descendente: "default",
      };
      this.icon = iconValues[icon];
      this.filters.sortDays = sortValues[sortDays];
      this.text = textValues[text];
      this.getComplaints();
    },
    handleChangeFile(file, fileList) {
      let isValid = this.onBeforeUpload(file.raw);
      if (isValid) {
        this.updateComplaintForm.file = file.raw;
      } else {
        fileList.pop();
      }

      console.log(424, file, fileList);
    },
    handleRemoveFile(file, fileList) {
      this.updateComplaintForm.file = null;
    },
    handleExceedFile(file, fileList) {
      this.$message.warning({
        showClose: true,
        message: `Solo puedes subir 1 archivo, elimine el anterior e intente nuevamente.`,
      });
    },
    remplaceParam(params) {
      // let queryParams = new URLSearchParams(window.location.search);
      let paramsCopy = this.copy(params);
      if (this.truthty(paramsCopy.monthRange)) {
        paramsCopy.monthRange = [
          moment(this.monthRange[0], "_MM/yyyy"),
          moment(this.monthRange[1], "_MM/yyyy"),
        ];
      }
      let str = this.objectToUrlPath(paramsCopy);
      history.replaceState(null, null, "?" + str.toString());
    },
    selectAll(values, type, props) {
      let addAll = values.includes("all");
      if (addAll) {
        const sup = this.truthty(props) ? props : type;
        debugger;
        this.filters[type] =
          type !== "anonymous"
            ? this.$props[sup].map((x) => x.id)
            : this.optionsAnonymous.map((x) => x.value);
      }
    },
    clean() {
      const {
        icon,
        text,
        filters: { sortDays },
      } = this;
      const sortValues = {
        "": "",
        sortBy: "",
        sortByDesc: "",
      };
      const iconValues = {
        "el-icon-d-caret": "el-icon-d-caret",
        "el-icon-caret-top": "el-icon-d-caret",
        "el-icon-caret-bottom": "el-icon-d-caret",
      };
      const textValues = {
        default: "default",
        ascendente: "default",
        descendente: "default",
      };
      this.icon = iconValues[icon];
      this.filters.sortDays = sortValues[sortDays];
      this.text = textValues[text];

      this.filters = {
        anonymous: [],
        code: "",
        countries: [],
        monthRange: [],
        states: [],
        text: "",
        types: [],
        sortDays: "",
      };
      this.getComplaints();
    },
  },
  mounted() {
    this.types[this.types.length - 1].id = -1;
    let params = this.urlPathToObject();
    let keys = Object.keys(params);
    keys.forEach((key) => {
      if (this.truthty(params[key])) {
        if (key == "monthRange") {
          this.monthRange = [
            moment(params[key][0], "_MM/yyyy"),
            moment(params[key][1], "_MM/yyyy"),
          ];
        } else if (Array.isArray(this.filters[key])) {
          this.filters[key] = Array.isArray(params[key])
            ? params[key]
            : [params[key]];
        } else {
          this.filters[key] = params[key];
        }
      }
    });
    console.log(this.filters);
    this.getComplaints();
    if (this.checkMobile() == false) {
      this.activeNames = ["1"];
    }
  },
};
</script>

<style>
.el-dialog {
  width: 490px !important;
}
</style>
