export default '<div>\
  <p>\
    Los datos personales que el Responsable trate son directamente\
    comunicados por el propio usuario al rellenar el presente\
    formulario y serán tratados por DRAKE FOOD SERVICES\
    INTERNATIONAL (en adelante, "DFSI" o el "Responsable")\
    con domicilio en Calle de la Isla de\
    Hierro 7, Oficina 1.2, 28703 Madrid.\
  </p> <br>\
  <p>\
    La finalidad del tratamiento es gestionar\
    la correspondiente denuncia, realizando, según sea el caso,\
    las investigaciones oportunas de los hechos denunciados y\
    adoptando las medidas correctivas pertinentes, y,\
    en caso de ser necesario, informar al interesado\
    (a través de cualquier medio, electrónico o no)\
    sobre el resultado del procedimiento. El usuario ha de\
    ser consciente de que, en el supuesto de declarar y\
    comunicar al Responsable datos de terceras personas,\
    garantiza la veracidad de la información comunicada,\
    así como que ha obtenido de forma inequívoca el oportuno\
    consentimiento por parte de dichas personas para comunicar\
    sus datos y, por consiguiente, ha informado a estas sobre\
    las implicaciones de dicha comunicación de sus datos al\
    Responsable.\
  </p> <br>\
  <p>\
    En caso de negativa al tratamiento de datos personales,\
    no podremos tramitar su denuncia.\
  </p> <br>\
  <p>\
    La base legal para el tratamiento de sus datos\
    según la finalidad indicada es: (i) el interés\
    legítimo del Responsable (incluidas cualquiera\
    de las sociedades que componen su grupo de empresas)\
    de garantizar la legalidad y el cumplimiento por\
    parte de sus empleados, de cualquier proveedor o\
    tercero que se relacione con el Responsable (incluidas\
    cualquiera de las sociedades que componen su grupo de\
    empresas) del Código Ético o cualquier otra normativa interna;\
    y (ii) el interés público cuando se trata de garantizar el\
    cumplimiento de normas legales.\
  </p> <br>\
  <p>\
    Los citados datos de carácter personal podrán ser\
    cedidos a las sociedades que conforman DFSI en caso de\
    ser necesario para el objeto de la investigación,\
    tramitación y/o resolución. Así mismo, podremos comunicar\
    sus datos en caso de obligación legal o interés legítimo\
    (entre las que pueden estar las comunicaciones a las Fuerzas\
    y Cuerpos de Seguridad del Estado, Administraciones públicas,\
    Jueces y Tribunales, etc.).\
  </p> <br>\
  <p>\
    Los datos que nos facilita cuando envía una denuncia\
    se conservarán durante el tiempo imprescindible para decidir\
    si debe iniciarse una investigación y se suprimirán en\
    todo caso, transcurridos 3 meses desde su introducción,\
    salvo que su conservación sea necesaria para dejar evidencia\
    del funcionamiento del modelo de prevención de delitos.\
    Dichos datos se conservarán de forma anonimizada.\
  </p> <br>\
  <p>\
    El usuario, si lo desea, tiene derecho a solicitar el\
    acceso a sus datos personales, su rectificación (si fueren\
    inexactos), su supresión, limitación del tratamiento u oposición\
    (siempre que concurran las condiciones legales para ello),\
    solicitar el derecho a la portabilidad de sus datos (cuando\
    proceda), así como a no ser objeto de decisiones basadas\
    únicamente en el tratamiento automatizado de sus datos (cuando\
    proceda). Para ello, deberá dirigir una comunicación al\
    Responsable o al Delegado de Protección de Datos\
    <a href="mailto:dataprotection@pjespana.es">dataprotection@pjespana.es</a>, o  a las direcciones de\
    contacto incluidas en la política de privacidad del apartado\
    "Legal", (accesible a través del siguiente enlace:\
    <a href="https://www.papajohns.es/política-de-privacidad" target="_blank">www.papajohns.es/política-de-privacidad)</a> \
     indicando su nombre, apellidos y DNI junto con la Ref. \
     Tratamiento datos Canal denuncias.\
  </p> <br>\
  <p>\
    De igual forma, el usuario que entienda que se han visto\
    vulnerados sus derechos en materia de protección de datos\
    o tenga cualquier reclamación relativa a su información\
    personal podrá formular una queja o reclamación ante el\
    Delegado de Protección de Datos del Responsable\
    (<a href="mailto:dataprotection@pjespana.es" >dataprotection@pjespana.es</a>) o ante la Agencia de Protección\
    de Datos.\
  </p>\
</div>';