// CommitChart.js
import { Bar,mixins,generateChart } from 'vue-chartjs';
const { reactiveProp } = mixins
import Chart from 'chart.js'

// const CustomLine = generateChart('', 'Bar')
// console.log(this.id);

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options', 'chartData'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}