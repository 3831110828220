<template>
  <div class="bg-light mt-3 px-md-4 px-3 py-2">
    <div>
      <div v-if="errors.length">
        <el-alert type="error">
          <ul class="mb-0">
            <li v-for="(error, index) in errors" v-bind:key="index">
              {{ error.message }}
            </li>
          </ul>
        </el-alert>
      </div>
      <div id="form_output"></div>

      <el-collapse class="w-100 border-bottom-0 mb-3" v-model="activeNames">
        <el-collapse-item name="1" class="div-filters">
          <template slot="title"
            ><h5 class="pl-3"><i class="el-icon-plus mr-2" />Nuevo país</h5>
          </template>

          <el-form
            id="local_form"
            ref="ruleForm"
            :rules="rules"
            :model="local"
            @submit.prevent="submitForm('ruleForm')"
          >
            <div
              class="
                row
                w-100
                mx-0
                px-1
                mb-4
                d-flex
                justify-content-between
                align-items-end
              "
            >
              <el-form-item class="col-md-5 m-0" prop="name" id="name">
                <label class="m-0">Nombre de país</label>
                <el-input
                  v-model="local.name"
                  placeholder="Ingrese nombre del país"
                  maxlength="35"
                />
              </el-form-item>
              <el-form-item
                class="col-md-5 m-0"
                prop="internal_code"
                id="internal_code"
              >
                <label class="m-0">Abreviación</label>
                <el-input
                  v-model="local.internal_code"
                  placeholder="Ingrese la abreviación"
                  maxlength="10"
                />
              </el-form-item>
              <div class="col-md-2 pt-3 text-right">
                <button
                  @click.prevent="submitForm('ruleForm')"
                  class="btn btn-info text-white"
                >
                  {{ isEdition ? "Actualizar" : "Guardar" }}
                </button>
              </div>
            </div>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- ------------------------------------------------------- -->
    <div class="row w-100 mx-0">
      <el-table
        :data="countriesList"
        style="width: 100%"
        max-height="500"
        v-loading="waiting"
        :default-sort="{ prop: 'id', order: 'ascending' }"
      >
        <el-table-column prop="id" label="Cód" width="90" sortable>
        </el-table-column>
        <el-table-column
          prop="internal_code"
          label="Abbr"
          min-width="200"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="Nombre del país"
          min-width="200"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="Estado"
          min-width="200"
          :filters="[
            { text: 'Activo', value: 1 },
            { text: 'Inactivo', value: 0 },
          ]"
          :filter-method="filterState"
        >
          <template slot-scope="props">
            <el-switch
              class="align-items-center switch-compact switch"
              @change="editCountryState(props.row, props.$index)"
              :value="props.row.active == 1"
              active-text="Activo"
              inactive-text="Inactivo"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          class-name="item-center"
          fixed="right"
          label="Acciones"
          min-width="100"
        >
          <template slot-scope="props">
            <el-button
              size="small"
              @click="edit(props.row, props.$index)"
              :type="isEdition && props.$index == index ? 'warning' : 'primary'"
              :icon="
                isEdition && props.$index == index
                  ? 'el-icon-close'
                  : 'el-icon-edit'
              "
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["countries"],
  data() {
    return {
      activeNames: [],
      local: {
        id: "",
        internal_code: "",
        name: "",
        active: true,
      },
      rules: {
        internal_code: [
          {
            required: true,
            message: "Debe escribir la abrevición",
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "Debe escribir el nombre del país",
            trigger: ["change", "blur"],
          },
        ],
      },
      countriesList: this.countries,
      isEdition: false,
      index: null,
      errors: [],
      waiting: false,
    };
  },
  methods: {
    submitForm(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid, rules) => {
        console.log(valid, rules);
        if (valid) {
          this.submit();
        } else {
          this.goToError(rules);
        }
      });
    },
    filterState(value, row) {
      return row.active == value;
    },
    editCountryState(row, index) {
      const { id, name, internal_code, active } = row;
      this.local = {
        id,
        name,
        internal_code,
        active: active == 1 ? 0 : 1,
      };
      this.index = index;
      this.submitEdit();
    },
    edit(row, index) {
      const { id, internal_code, name, active } = row;
      if (this.isEdition) {
        this.isEdition = false;
        this.clearForm();
        this.index = null;
      } else {
        this.isEdition = true;
        this.local = {
          id,
          internal_code,
          name,
          active,
        };
        this.index = index;
      }
    },
    submit() {
      if (this.isEdition) {
        this.submitEdit();
      } else {
        this.submitCreate();
      }
    },
    submitCreate() {
      this.waiting = true;
      const url = `${window.location.origin}/countries`;
      axios
        .post(url, this.local)
        .then((res) => {
          this.waiting = false;
          this.countriesList.push(res.data.country);
          this.clearForm();
          this.$message({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          this.waiting = false;
          if (this.truthty(error.response.data)) {
            this.errors = [];
            if (this.truthty(error.response.data.server)) {
              this.$message({
                message: error.response.data.message,
                type: "error",
              });
            } else {
              error.response.data.forEach((row) =>
                this.errors.push({ message: row })
              );
            }
          }
          // this.error = 1;
        });
    },
    submitEdit() {
      this.waiting = true;
      const url = `${window.location.origin}/countries/${this.local.id}`;
      axios
        .put(url, this.local)
        .then((res) => {
          this.waiting = false;
          const countriesListAux = this.countriesList.map((row, index) => {
            if (index == this.index) {
              return this.local;
            } else return row;
          });
          this.countriesList = this.copy(countriesListAux);
          this.isEdition = false;
          this.clearForm();
          this.$message({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          this.waiting = false;
          this.$message({
            message:
              "Error en los datos ingresados. validar si abreviación no se encuentra repetida.",
            type: "error",
          });
          // this.error = 1;
        });
    },
    clearForm() {
      this.local = {
        id: "",
        internal_code: "",
        name: "",
      };
      this.$refs["ruleForm"].resetFields();
    },
  },
  mounted() {
    if (this.checkMobile() == false) {
      this.activeNames = ["1"];
    }
  },
};
</script>
