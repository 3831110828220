<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <div id="form_output"></div>

            <el-collapse
                class="w-100 border-bottom-0 mb-3"
                v-model="activeNames"
            >
                <el-collapse-item name="1" class="div-filters">
                    <template slot="title">
                        <h5 class="pl-3">
                            <i class="el-icon-plus mr-2">
                                Nuevo tipo de denuncia
                            </i>
                        </h5>
                    </template>

                    <el-form
                        class="col w-100 p-0"
                        id="local_form"
                        ref="ruleForm"
                        :rules="rules"
                        :model="complaintType"
                        @submit.prevent="submitForm('ruleForm')"
                    >
                        <div
                            class="row w-100 mx-0 px-1 mb-4 d-flex justify-content-between align-items-end"
                        >
                            <el-form-item
                                class="col-md-4 m-0"
                                prop="type"
                                id="type"
                            >
                                <label class="m-0">Tipo</label>

                                <el-input
                                    class="w-100"
                                    v-model="complaintType.type"
                                    placeholder="Ingrese el tipo de denuncia"
                                />
                            </el-form-item>

                            <el-form-item
                                class="col-md-4 m-0 d-flex flex-column"
                                prop="anonymous"
                                id="anonymous"
                            >
                                <label class="m-0">Permite anónimo</label>
                                <el-switch
                                    class="align-items-center switch-compact switch"
                                    v-model="complaintType.anonymous"
                                    active-text="Sí"
                                    inactive-text="No"
                                >
                                </el-switch>
                            </el-form-item>

                            <el-form-item
                                class="col-md-4 m-0"
                                prop="anonymous"
                                id="anonymous"
                            >
                                <label class="m-0"
                                    >Contemplada en Ley Karin</label
                                >
                                <el-switch
                                    class="align-items-center switch-compact switch"
                                    v-model="complaintType.is_karin"
                                    active-text="Sí"
                                    inactive-text="No"
                                >
                                </el-switch>
                            </el-form-item>
                            <el-form-item
                                class="col-md-6 m-0"
                                prop="description"
                                id="description"
                            >
                                <label class="m-0">Descripción</label>

                                <el-input
                                    type="textarea"
                                    class="w-100"
                                    :autosize="{ minRows: 2, maxRows: 6 }"
                                    v-model="complaintType.description"
                                    placeholder="Ingrese la descripción del tipo de denuncia"
                                />
                            </el-form-item>

                            <div class="col-md-2 pt-3 text-right">
                                <button
                                    @click.prevent="submitForm('ruleForm')"
                                    class="btn btn-info text-white"
                                >
                                    {{ isEdition ? "Actualizar" : "Guardar" }}
                                </button>
                            </div>
                        </div>
                    </el-form>

                    <!-- ---------------------------------------------------------------------------------- -->
                </el-collapse-item>
            </el-collapse>
        </div>

        <div class="row w-100 mx-0">
            <el-table
                :data="complaintTypesTable"
                style="width: 100%; height: 100%"
                max-height="500"
                v-loading="waiting"
                :default-sort="{ prop: 'id', order: 'ascending' }"
            >
                <el-table-column prop="id" label="Cód" width="90" sortable>
                </el-table-column>

                <el-table-column
                    sortable
                    prop="type"
                    label="Tipo de denuncia"
                    min-width="90%"
                >
                </el-table-column>

                <el-table-column
                    prop="description"
                    label="Descripción"
                    min-width="230%"
                    sortable
                >
                </el-table-column>

                <el-table-column
                    min-width="100%"
                    label="Estado"
                    :filters="[
                        { text: 'Activo', value: 1 },
                        { text: 'Inactivo', value: 0 },
                    ]"
                    :filter-method="filterState"
                >
                    <template slot-scope="scope">
                        <el-switch
                            class="align-items-center switch-compact switch"
                            @change="
                                editComplaintTypeState(scope.row.id);
                                setSelectedRow(scope.$index);
                            "
                            :value="scope.row.active == 1"
                            active-text="Activo"
                            inactive-text="Inactivo"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="100%"
                    label="Anónimo"
                    :filters="[
                        { text: 'Sí', value: 1 },
                        { text: 'No', value: 0 },
                    ]"
                    :filter-method="filterState"
                >
                    <template slot-scope="scope">
                        <el-switch
                            class="align-items-center switch-compact switch"
                            @change="editComplaintTypeAnonymous(scope.row.id)"
                            :value="scope.row.anonymous == 1"
                            active-text="Sí"
                            inactive-text="No"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="100%"
                    label="Ley Karín"
                    :filters="[
                        { text: 'Sí', value: 1 },
                        { text: 'No', value: 0 },
                    ]"
                    :filter-method="filterState"
                >
                    <template slot-scope="scope">
                        <el-switch
                            class="align-items-center switch-compact switch"
                            @change="editComplaintTypeKarin(scope.row.id)"
                            :value="scope.row.is_karin == 1"
                            active-text="Sí"
                            inactive-text="No"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    class-name="item-center"
                    fixed="right"
                    label="Acciones"
                    min-width="50"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            @click="
                                editComplainType(scope.row.id);
                                setSelectedRow(scope.$index);
                            "
                            :type="
                                isEdition && scope.$index == index
                                    ? 'warning'
                                    : 'primary'
                            "
                            :icon="
                                isEdition && scope.$index == index
                                    ? 'el-icon-close'
                                    : 'el-icon-edit'
                            "
                        />
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    props: ["complaintTypes"],
    data() {
        return {
            activeNames: [],
            complaintTypesTable: [...this.$props.complaintTypes],
            complaintType: {
                type: undefined,
                active: true,
                description: "",
            },
            rules: {
                type: [
                    {
                        required: true,
                        message: "Debe escribir el tipo de la denuncia",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            index: -1,
            editedComplaintTypeId: undefined,
            isEdition: false,
            waiting: false,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        clearForm() {
            this.complaintType = {
                type: undefined,
            };
            this.$refs["ruleForm"].resetFields();
        },
        clearEditData() {
            this.index = -1;
            this.isEdition = false;
            this.editedComplaintTypeId = undefined;
        },
        setSelectedRow(complaintIndex) {
            if (this.index == complaintIndex) {
                this.clearEditData();
                this.clearForm();
                return;
            }
            this.index = complaintIndex;
        },
        filterState(value, row) {
            return row.active == value;
        },
        editComplainType(complaintIndex) {
            const complaintType = this.complaintTypesTable.find(
                (row) => row.id === complaintIndex
            );
            const { id } = complaintType;
            this.isEdition = true;
            this.editedComplaintTypeId = complaintIndex;

            this.complaintType = { ...complaintType };
        },
        editComplaintTypeState(complaintIndex) {
            const complaintType = this.complaintTypesTable.find(
                (row) => row.id === complaintIndex
            );
            const { id, type, active, description } = complaintType;
            this.editedComplaintTypeId = complaintIndex;
            const data = {
                description,
                type,
                active: active == 1 ? 0 : 1,
            };
            this.submitEdit(data);
        },
        editComplaintTypeKarin(complaintIndex) {
            const complaintType = this.complaintTypesTable.find(
                (row) => row.id === complaintIndex
            );
            const { type, active, is_karin, description } = complaintType;
            this.editedComplaintTypeId = complaintIndex;
            const data = {
                description,
                type,
                active,
                is_karin: is_karin == 1 ? 0 : 1,
            };
            this.submitEdit(data);
        },
        editComplaintTypeAnonymous(complaintIndex) {
            const complaintType = this.complaintTypesTable.find(
                (row) => row.id === complaintIndex
            );
            const { type, active, anonymous, description } = complaintType;
            this.editedComplaintTypeId = complaintIndex;
            const data = {
                description,
                type,
                active,
                anonymous: anonymous == 1 ? 0 : 1,
            };
            this.submitEdit(data);
        },
        submit() {
            if (this.isEdition) {
                const data = { ...this.complaintType };

                this.submitEdit(data);
            } else {
                this.submitCreate();
            }
        },
        submitCreate() {
            this.waiting = true;
            const url = `${window.location.origin}/complaint-types/create`;
            axios
                .post(url, this.complaintType)
                .then((res) => {
                    this.waiting = false;
                    this.complaintTypesTable.push(res.data.newComplaintType);
                    this.clearForm();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                    this.$message.error(
                        "Ha ocurrido un error al crear el tipo de infracción"
                    );
                });
        },
        submitEdit(complaintTypeData) {
            this.waiting = true;
            const url = `${window.location.origin}/complaint-types/update/${this.editedComplaintTypeId}`;
            axios
                .put(url, complaintTypeData)
                .then((res) => {
                    this.waiting = false;
                    let complaints = this.complaintTypesTable.map((row) => {
                        if (row.id === this.editedComplaintTypeId) {
                            return res.data.complaint_type;
                        }
                        return row;
                    });
                    this.complaintTypesTable = this.copy(complaints);
                    this.clearForm();
                    this.clearEditData();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    console.error(error);
                    this.$message.error(
                        "Ha ocurrido un error al editar el tipo de infracción"
                    );
                });
        },
    },
    mounted() {
        if (this.checkMobile() == false) {
            this.activeNames = ["1"];
        }
    },
};
</script>
