<template>
  <div>
    <button
      type="button"
      class="btn btn-outline-primary btn-help"
      data-toggle="modal"
      data-target="#quickReport"
      @click="resetForm('ruleForm')"
    >
      <i class="fas fa-pen fa-2x"></i>
    </button>
    <div
      class="modal fade"
      id="quickReport"
      tabindex="-1"
      role="dialog"
      aria-labelledby="quickReport"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header pb-0">
            <h4 class="modal-title">Línea ética simple</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="far fa-times-circle"></i>
            </button>
          </div>
          <div class="container">
            <small style="font-size: small">
              Si quieres informar de un incidente de manera completa, ingresa a
              la
              <a href="/linea-etica">línea ética</a>.
            </small>
          </div>
          <div class="modal-body">
            <el-form
              v-show="activeForm"
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
            >
              <div class="text-left mb-3">
                <p>
                  Antes de empezar, por favor leer cuidadosamente la política de
                  privacidad.
                </p>
                <div class="form-group">
                  <el-form-item id="consent" prop="consent">
                    <el-checkbox v-model="ruleForm.consent">
                      Estoy de acuerdo con la
                      <a
                        href="pdf/Política-de-Privacidad-VE.pdf"
                        target="_blank"
                        >Política de privacidad</a
                      >.
                    </el-checkbox>
                  </el-form-item>
                </div>
              </div>
              <hr />
              <el-form-item label="Qué acción quieres realizar:" class="w-100">
                <el-radio v-model="ruleForm.suggestion" label="0"
                  >Incidente</el-radio
                >
                <el-radio v-model="ruleForm.suggestion" label="1"
                  >Sugerencia</el-radio
                >
              </el-form-item>
              <el-form-item
                v-show="ruleForm.suggestion == 0"
                label="¿Qué?"
                class="w-100"
                id="complaints_type_id"
                prop="complaints_type_id"
              >
                <el-select
                  v-show="ruleForm.suggestion == 0"
                  v-model="filterComplaint"
                  @change="filterComplaintsTypeById"
                  class="w-100"
                  placeholder="Selecciona el tipo de incidente"
                  filterable
                >
                  <el-option
                    v-for="(complaint_type, index) in complaints_types"
                    :key="complaint_type.id"
                    :label="complaint_type.type"
                    :value="index"
                  >
                    <el-popover
                      style="word-break: normal"
                      placement="right-start"
                      title="¿Qué significa esto?"
                      width="250"
                      :disabled="handlePopover(complaint_type.description)"
                      trigger="hover"
                      :content="complaint_type.description"
                    >
                      <div slot="reference">{{ complaint_type.type }}</div>
                    </el-popover>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="¿Dónde?"
                class="w-100"
                id="country_id"
                prop="country_id"
                filterable
                ><!-- país donde ocurrió-->
                <el-select
                  v-model="ruleForm.country_id"
                  class="w-100"
                  placeholder="Selecciona el país en donde sucedió"
                >
                  <el-option
                    v-for="country in countries"
                    :key="country.id"
                    :label="country.name"
                    :value="country.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="¿Cuál es su sugerencia o preocupación?"
                class="w-100"
                prop="description"
                id="description"
              >
                <!-- Sugerencia o preocupación -->
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="Escriba su sugerencia o reclamo"
                  v-model="ruleForm.description"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.suggestion == 1"
                label="¿A qué área o personas impactan?"
                class="w-100"
                prop="impact"
              >
                <!-- A qué área o personas impactan -->
                <el-input
                  v-show="ruleForm.suggestion == 1"
                  placeholder="¿A qué área o personas impactan?"
                  v-model="ruleForm.impact"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="Email:"
                class="w-100"
                id="contact_email"
                prop="contact_email"
              >
                <!-- email -->
                <el-input
                  placeholder="Por favor escribe tu correo electrónico"
                  v-model="ruleForm.contact_email"
                ></el-input>
              </el-form-item>
              <!-- Cargar archivos -->
              <el-upload
                class="upload-demo"
                action="#"
                :on-change="handleChange"
                :on-remove="handleRemove"
                multiple
                :before-upload="onBeforeUpload"
                :limit="3"
                :on-exceed="handleExceed"
                :auto-upload="false"
                accept="image/jpeg,image/jpg,image/png, application/pdf, audio/mp3,audio/wap, video/mpg, video/mp4, video/mob"
              >
                <el-button size="small" type="primary"
                  >Click para subir archivo</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  Tamaño máximo de archivos (imagen, pdf, audio o video) 10MB
                </div>
              </el-upload>

              <hr class="my-2" />
              <div class="row">
                <div class="col d-flex justify-content-center">
                  <vue-recaptcha
                    ref="recaptcha"
                    sitekey="6LfcdDQeAAAAAARRLHeDMCH-w0T6iQ9boMxIvFQF"
                    :loadRecaptchaScript="true"
                    @verify="verifyrecaptcha"
                    @expired="onCaptchaExpired"
                     type="invisible"
                  />
                </div>
              </div>
              <hr class="my-2" />
              <el-row type="flex" justify="space-between">
                <el-button type="info" plain data-dismiss="modal"
                  >Cerrar</el-button
                >

                <el-button
                  :disabled="!truthty(recaptchaValue)"
                  v-if="!waiting"
                  type="primary"
                  @click.prevent="submitForm('ruleForm')"
                  >Guardar</el-button
                >
                <el-button v-if="waiting" type="primary" :loading="true"
                  >Guardando...</el-button
                >
              </el-row>
            </el-form>
            <div v-show="!activeForm">
              <el-alert
                class="my-3"
                title="Su denuncia ha sido enviada"
                type="success"
                center
                show-icon
                :closable="false"
              />
              <h5 class="text-center">
                Por favor anotar los siguientes datos para consultar por el
                estado de la denuncia.
              </h5>
              <p class="my-3 text-center">El código de denuncia es:</p>
              <h3 class="my-3 text-center">
                {{ this.resume.codigo }}
              </h3>
              <p class="my-3 text-center">La contraseña es:</p>
              <h3 class="my-3 text-center">
                {{ this.resume.pass }}
              </h3>
              <hr />
              <el-row type="flex" justify="space-between">
                <el-button
                  type="info"
                  plain
                  data-dismiss="modal"
                  @click="resetForm('ruleForm')"
                  >Cerrar</el-button
                >
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["complaints_types", "countries", "recaptcha"],
  data() {
    return {
      waiting: false,
      filterComplaint: "",
      description: "",
      activeForm: true,
      resume: {},
      recaptchaValue: null,
      ruleForm: {
        consent: false,
        complaints_type_id: this.complaints_type_id
          ? this.complaints_type_id
          : 0,
        country_id: "",
        description: "",
        impact: this.impact ? this.impact : "",
        contact_email: "",
        fileList: [],
        recaptchaValue:''
      },
      filteredComplaint: {},
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid, rules) => {
        if (valid) {
          this.waiting = true;
          const url = `${window.location.origin}/complaint/postdata4`;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          let formData = new FormData();
          formData.append("payload", JSON.stringify(this.ruleForm));
          this.ruleForm.fileList.map((x, index) => {
            formData.append("file" + (index + 1), x);
          });
          axios
            .post(url, formData, config)
            .then((res) => {
              if (res.data.success) {
                this.waiting = false;
                this.activeForm = false;
                this.resume = res.data;
              }
            })
            .catch((e) => {
              this.waiting = false;
            });
        } else {
          this.goToError(rules, "consent");
        }
      });
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    verifyrecaptcha(response) {
      this.recaptchaValue = response;
      this.ruleForm.recaptchaValue = response;
    },
    filterComplaintsTypeById(complaintsid) {
      let auxComplaint = this.complaints_types[complaintsid];
      this.ruleForm.complaints_type_id = auxComplaint.id;
      // this.description = auxComplaint.description;
    },
    onBeforeUpload(file) {
      console.log("archivo", file);
      const validTypes = [
        "image/jpeg",
        "image/pjg",
        "image/png",
        "application/pdf",
        "audio/mp3",
        "audio/wap",
        "video/mpg",
        "video/mp4",
        "video/mob",
      ];
      let isValid = validTypes.includes(file.type);

      const isLt10M = file.size / 10240 / 10240 < 10;

      if (!isValid) {
        this.$message.error(
          "El archivo subido no cumple con los formatos aceptados"
        );
      }
      if (!isLt10M) {
        this.$message.error("El archivo subido supera el tamaño maximo");
      }
      return isValid && isLt10M;
    },
    handleChange($e, fileList) {
      let isValid = this.onBeforeUpload($e.raw);
      if (isValid) {
        this.ruleForm.fileList.push($e.raw);
      } else {
        fileList.pop();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.fileList = fileList;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `El límite es de 3 archivos. Puedes eliminar algún archivo ya cargado para subir uno nuevo.`
      );
    },
    resetForm(formName) {
      this.waiting = false;
      this.activeForm = true;
      this.resume = {};
      this.ruleForm = {
        consent: false,
        complaints_type_id: "",
        country_id: "",
        description: "",
        impact: "",
        contact_email: "",
        fileList: [],
        recaptchaValue: null,
      };
      this.$refs.recaptcha.reset();
      this.$refs[formName].resetFields();
    },
    handlePopover(description) {
      return !this.truthty(description);
    },
  },
  computed: {
    rules() {
      var checkComplaintData = (rule, value, callback, source, options) => {
        const isSuggestion = this.ruleForm.suggestion;
        if (isSuggestion === "0") {
          if (value === "") {
            return callback(new Error("Debe informar el tipo de incidente"));
          }
        }
        return callback();
      };
      var checkImpactData = (rule, value, callback, source, options) => {
        const isSuggestion = this.ruleForm.suggestion;
        if (isSuggestion === "1") {
          if (value === "") {
            return callback(
              new Error("Debe informar a que áreas o personas impacta")
            );
          } else {
            [
              {
                required: true,
                message: "Indique a qué área o personas impactan",
                trigger: "blur",
              },
              {
                min: 10,
                max: 200,
                message: "Por favor indicar más detalles",
                trigger: "blur",
              },
            ];
          }
        }
        return callback();
      };
      var checkContent = (rule, value, callback) => {
        if (!value) {
          switch (rule.field) {
            case "consent":
              return callback(
                new Error("Debe dar su consentimiento antes de continuar.")
              );
            case "country_id":
              return callback(new Error("Debe seleccionar país / entidad."));
            case "contact_email":
              return callback(new Error("Debe informar el email"));
            case "description":
              return callback(new Error("Por favor describir la situación"));
            default:
              return callback();
          }
        } else {
          if (rule.field === "contact_email" && !this.isEmailValid(value)) {
            return callback(new Error("Formato de email incorrecto"));
          }
        }
        return callback();
      };
      return {
        consent: [{ validator: checkContent, trigger: "change" }],
        complaints_type_id: [
          { validator: checkComplaintData, trigger: "change" },
        ],
        country_id: [{ validator: checkContent, trigger: "change" }],
        contact_email: [
          { validator: checkContent, trigger: ["change", "blur"] },
        ],
        description: [
          { validator: checkContent, trigger: ["change", "blur"] },
          {
            min: 10,
            max: 200,
            message: "Por favor informar más detalles de la situación",
            trigger: ["change", "blur"],
          },
        ],
      };
    },
  },
  mounted() {},
  validations() {
    return {
      ruleForm: this.rules,
    };
  },
};
</script>
