<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <div v-if="errors.length">
                <el-alert type="error">
                    <ul class="mb-0">
                        <li v-for="(error, index) in errors" v-bind:key="index">
                            {{ error.message }}
                        </li>
                    </ul>
                </el-alert>
            </div>

            <div id="form_output"></div>

            <el-collapse class="w-100 border-bottom-0 mb-3">
                <el-collapse-item name="1" class="div-filters">
                    <template slot="title">
                        <h5 class="pl-3">
                            <i class="el-icon-plus mr-2" />
                            Nuevo Archivo a asociar
                        </h5>
                    </template>

                    <el-form
                        id="local_form"
                        @click.prevent="submitForm('ruleForm')"
                        ref="ruleForm"
                        :rules="rules"
                        :model="ruleForm"
                    >
                        <div
                            class="row w-100 mx-0 px-1 mb-4 d-flex justify-content-between align-items-end"
                        >
                            <el-form-item
                                prop="settingPageId"
                                class="col-md-12 mb-0"
                            >
                                <label class="mb-0"
                                    >Seleccione la página al que desea asociar
                                    archivo</label
                                >

                                <el-select
                                    v-model="ruleForm.settingPageId"
                                    class="w-100"
                                >
                                    <el-option
                                        v-for="settingPage in settingsPages"
                                        :key="settingPage.id"
                                        :label="
                                            settingPage.title +
                                            ' | ' +
                                            settingPage.setting.country.name +
                                            ' | ' +
                                            settingPage.setting.language.name
                                        "
                                        :value="settingPage.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-md-5 m-0" prop="title">
                                <label class="mb-0">Título</label>

                                <el-input
                                    v-model="ruleForm.title"
                                    placeholder="Ingrese el título del archivo"
                                />
                            </el-form-item>

                            <el-form-item
                                prop="fileList"
                                class="col-md-5 m-0"
                                id="fileList"
                            >
                                <!-- archivo -->

                                <el-upload
                                    class="upload-demo text-center d-flex"
                                    action="#"
                                    :before-upload="onBeforeUpload"
                                    :on-change="handleChange"
                                    :on-remove="handleRemove"
                                    :auto-upload="false"
                                    :limit="1"
                                    :on-exceed="handleExceed"
                                    :file-list="ruleForm.fileList"
                                    accept="pdf/*"
                                >
                                    <el-button
                                        class="mt-2 mr-2"
                                        size="small"
                                        type="primary"
                                    >
                                        Click para seleccionar archivo
                                    </el-button>
                                </el-upload>
                            </el-form-item>

                            <div class="col text-right">
                                <button
                                    @click.prevent="submitForm('ruleForm')"
                                    class="btn btn-info text-white"
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </div>
        <!-- ---------------------------------------------- -->
        <div class="row w-100 mx-0">
            <el-table
                :data="pagesLinksList"
                v-loading="waiting"
                style="width: 100%; height: 100%"
                max-height="500"
                :default-sort="{ prop: 'title', order: 'ascending' }"
            >
                <el-table-column
                    prop="settings_page.title"
                    label="Vista"
                    min-width="250"
                    sortable
                />
                <el-table-column
                    prop="settings_page.setting.country.name"
                    label="División"
                    min-width="100"
                    sortable
                />
                <el-table-column
                    prop="settings_page.setting.language.name"
                    label="Idioma"
                    min-width="100"
                    sortable
                />
                <el-table-column
                    prop="title"
                    label="Nombre archivo"
                    min-width="100"
                    sortable
                >
                </el-table-column>
                <el-table-column
                    class-name="item-center"
                    fixed="right"
                    label="Acciones"
                    min-width="100"
                >
                    <template slot-scope="props">
                        <div class="row d-flex justify-content-center">
                            <el-button
                                size="small"
                                @click="showFile(props.row)"
                                type="primary"
                                icon="el-icon-document"
                            ></el-button>
                            <el-button
                                size="small"
                                @click="deleteFile(props.row)"
                                type="danger"
                                icon="el-icon-delete"
                            ></el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    props: ["pagesLinks", "settingsPages"],
    data() {
        return {
            ruleForm: {
                settingPageId: null,
                title: "",
                fileList: [],
            },
            rules: {
                settingPageId: [
                    {
                        required: true,
                        message:
                            "Debe seleccionar la página a la que desea asociar el archivo",
                        trigger: ["change", "blur"],
                    },
                ],
                title: [
                    {
                        required: true,
                        message:
                            "Debe escribir el nombre del archivo a mostrar",
                        trigger: ["change", "blur"],
                    },
                    {
                        min: 5,
                        message:
                            "Por favor informar el nombre a mostrar del archivo",
                        trigger: "blur",
                    },
                ],
                fileList: [
                    {
                        required: true,
                        message: "Debe seleccionar el archivo pdf a mostrar",
                        trigger: ["change", "blur"],
                    },
                ],
            },
            pagesLinksList: this.pagesLinks,
            isEdition: false,
            index: null,
            errors: [],
            buscar: "",
            waiting: false,
            setting_id: 0,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submitCreate();
                } else {
                    this.goToError(rules);
                }
            });
        },
        onBeforeUpload(file) {
            const validTypes = ["application/pdf"];
            let isValid = validTypes.includes(file.type);
            const isLt10M = file.size / 10240 / 10240 < 10;
            if (!isValid) {
                this.$message.error(
                    "El archivo subido no cumple con los formatos aceptados"
                );
            }
            if (!isLt10M) {
                this.$message.error(
                    "El archivo subido supera el tamaño maximo"
                );
            }
            return isValid && isLt10M;
        },
        handleChange($e) {
            let isValid = this.onBeforeUpload($e.raw);
            if (isValid) {
                this.ruleForm.fileList.push($e.raw);
                this.$refs["ruleForm"].validateField("fileList");
            } else {
                this.ruleForm.fileList = [];
                fileList.pop();
            }
        },
        handleRemove(file, fileList) {
            this.ruleForm.fileList = [];
            this.$refs["ruleForm"].validateField("fileList");
        },
        handleExceed(files, fileList) {
            this.$message.warning(`Solo puedes subir 1 archivo pdf.`);
        },
        submitCreate() {
            const url = `${window.location.origin}/pages-links`;
            this.waiting = true;
            let config = {
                headers: { "Content-Type": "multipart/form-data" },
            };
            let formData = new FormData();
            formData.append("payload", JSON.stringify(this.ruleForm));
            this.ruleForm.fileList.map((x, index) => {
                formData.append("file", x);
            });
            axios
                .post(url, formData, config)
                .then((res) => {
                    this.waiting = false;
                    this.pagesLinksList = res.data.datos;
                    this.clearForm();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    if (this.truthty(error.response.data)) {
                        this.errors = [];
                        if (this.truthty(error.response.data.server)) {
                            this.$message({
                                message: error.response.data.message,
                                type: "error",
                            });
                        } else {
                            this.$message({
                                message: "Error en los campos informados",
                                type: "error",
                            });
                        }
                    }
                });
        },
        clearForm() {
            this.pageLink = {
                settingPageId: null,
                title: "",
                file: [],
            };
            this.$refs["ruleForm"].resetFields();
        },
        showFile(row) {
            this.waiting = true;
            const url = `${window.location.origin}/pages-links/${row.id}`;
            axios.get(url).then((res) => {
                this.waiting = false;
                window.open(res.data.url, "_blank");
            });
        },
        deleteFile(row) {
            this.waiting = true;
            const fileId = row.id;
            const url = `${window.location.origin}/pages-links/${fileId}`;
            axios.delete(url).then((res) => {
                const pagesLinksAux = this.pagesLinksList.filter(
                    (row) => row.id != fileId
                );
                this.pagesLinksList = this.copy(pagesLinksAux);
                this.waiting = false;
                this.$message({
                    message: res.data.message,
                    type: "success",
                }).catch((e) => {
                    this.waiting = false;
                });
            });
        },
    },
};
</script>
