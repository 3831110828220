<template>
  <div class="denuncia-ahora">
    <button
      type="button"
      class="btn btn-estado-denuncia my-2"
      data-toggle="modal"
      data-target="#estadoReporte"
      v-on:click="resetForm()"
    >
      ESTADO DE LA DENUNCIA <i class="fas fa-chart-line fa-lg ml-2"></i>
    </button>

    <div
      class="modal fade"
      id="estadoReporte"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Estado denuncia</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="far fa-times-circle"></i>
            </button>
          </div>
          <div class="modal-body" style="overflow-y: auto">
            <el-form
              v-show="activeForm"
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
            >
              <el-alert
                class="mb-2"
                v-if="this.resume.success === false"
                :title="this.resume.error"
                type="error"
                @close="reset"
              />
              <el-form-item
                label="Código de denuncia:"
                class="w-100"
                prop="complaint_id"
              >
                <!-- complaint_id -->
                <el-input
                  placeholder="Ingrese el código de denuncia:"
                  v-model="ruleForm.complaint_id"
                  auto-complete="false"
                ></el-input>
              </el-form-item>
              <el-form-item label="Contraseña:" class="w-100" prop="password">
                <!-- password -->
                <el-input
                  type="password"
                  placeholder="Ingrese la contraseña"
                  v-model="ruleForm.password"
                  auto-complete="false"
                ></el-input>
              </el-form-item>
              <div class="col d-flex justify-content-center">
                <vue-recaptcha
                  ref="recaptcha"
                  sitekey="6LfcdDQeAAAAAARRLHeDMCH-w0T6iQ9boMxIvFQF"
                  :loadRecaptchaScript="true"
                  @verify="verifyrecaptcha"
                  @expired="onCaptchaExpired"
                  type="invisible"
                />
              </div>
              <el-row type="flex" justify="space-between">
                <el-button
                  class="ml-auto"
                  v-if="!waiting"
                  type="primary"
                  @click.prevent="submitForm('ruleForm')"
                  >Consultar</el-button
                >
                <el-button
                  class="ml-auto"
                  v-if="waiting"
                  type="primary"
                  :loading="true"
                  >Enviando...</el-button
                >
              </el-row>
            </el-form>
            <el-form
              v-show="!activeForm"
              :model="ruleForm2"
              status-icon
              :rules="rules"
              ref="ruleForm2"
              class="complaint-resume"
            >
              <div v-if="!activeForm">
                <div class="row mx-1">
                  <div v-show="isIncident" class="col-md-6">
                    Código de denuncia
                  </div>
                  <div v-show="isIncident" class="col-md-6 bg-light">
                    {{ this.resume.complaint ? this.resume.complaint.id : "" }}
                  </div>
                  <div v-show="isIncident" class="col-md-6">
                    Tipo de incidente
                  </div>
                  <div v-show="isIncident" class="col-md-6 bg-light">
                    {{ this.resume.complaint ? getComplaintType() : "" }}
                  </div>
                  <div v-show="isIncident" class="col-md-6">
                    ¿Hubo testigos?
                  </div>
                  <div v-show="isIncident" class="col-md-6 bg-light">
                    {{
                      this.resume.complaint
                        ? getWitnesses()
                        : "No hubo testigos"
                    }}
                  </div>
                  <div v-show="isIncident" class="col-md-6">
                    ¿Fue informado antes?
                  </div>
                  <div v-show="isIncident" class="col-md-6 bg-light">
                    {{ this.resume.complaint ? getReported() : "" }}
                  </div>
                  <div v-show="isSuggestion" class="col-md-6">
                    Código de sugerencia
                  </div>
                  <div v-show="isSuggestion" class="col-md-6 bg-light">
                    {{ this.resume.complaint ? this.resume.complaint.id : "" }}
                  </div>
                  <div v-show="isSuggestion" class="col-md-6">
                    Resultado esperado
                  </div>
                  <div v-show="isSuggestion" class="col-md-6 bg-light">
                    {{
                      this.resume.complaint
                        ? getExpectedResult()
                        : "Sin informar"
                    }}
                  </div>
                  <div v-show="isSuggestion" class="col-md-6">
                    Sugerencia ingresada
                  </div>
                  <div v-show="isSuggestion" class="col-md-6 bg-light">
                    {{
                      this.resume.complaint
                        ? getSuggestionText()
                        : "Sin informar"
                    }}
                  </div>
                  <div class="col-md-6">Estado</div>
                  <div class="col-md-6 bg-light">
                    {{ this.resume.complaint ? getLastState() : "" }}
                  </div>
                  <div class="col-md-6">Fecha registro</div>
                  <div class="col-md-6 bg-light">
                    {{
                      this.resume.complaint
                        ? this.resume.complaint.created_at
                        : ""
                    }}
                  </div>
                  <div class="col-md-6">Entidad</div>
                  <div class="col-md-6 bg-light">
                    {{ this.resume.complaint ? getCountry() : "Sin informar" }}
                  </div>
                  <div class="col-md-6">Descripción</div>
                  <div class="col-md-6 bg-light">
                    {{
                      this.resume.complaint
                        ? this.resume.complaint.description
                        : "Sin descripción"
                    }}
                  </div>
                  <div class="col-md-6">Comentarios</div>
                  <div
                    v-if="this.resume.complaint"
                    class="col-md-6 bg-light div-state-log"
                  >
                    <p
                      v-for="log in this.resume.complaint.state_logs"
                      :key="log.id"
                    >
                      <small>{{ formatDate(log.created_at) }}</small>
                      <span class="badge badge-info">{{
                        log.complaint_state.name
                      }}</span>
                      <br />{{ log.comment }}
                    </p>
                  </div>
                  <div class="col-md-6">Archivos</div>
                  <div
                    class="col-md-6 div-state-log"
                    v-if="
                      this.resume.complaint &&
                      this.resume.complaint.fileList.length > 0
                    "
                  >
                    <p
                      v-for="(file, index) in this.resume.complaint.fileList"
                      :key="index"
                    >
                      <a :href="file" target="_blank"
                        >Archivo {{ index + 1 }}</a
                      >
                    </p>
                  </div>
                  <div v-else class="col-md-6 bg-light">Sin archivos</div>
                  <div
                    class="
                      col-md-6
                      offset-md-6
                      w-100
                      div-state-log
                      d-flex
                      justify-content-end
                    "
                    v-if="
                      this.resume.complaint &&
                      this.resume.complaint.fileList.length < 3
                    "
                  >
                    <el-upload
                      class="upload-demo list-size-upload"
                      action="#"
                      :on-change="handleChange"
                      :on-remove="handleRemove"
                      multiple
                      :auto-upload="false"
                      :before-upload="onBeforeUpload"
                      :limit="3 - this.resume.complaint.fileList.length"
                      :on-exceed="handleExceed"
                      :file-list="fileList"
                      accept="image/jpeg,image/jpg,image/png, application/pdf, audio/mp3,audio/wap, video/mpg, video/mp4, video/mob"
                    >
                      <el-button size="small" type="primary"
                        >Clic para subir archivo</el-button
                      >
                      <div slot="tip" class="el-upload__tip">
                        Tamaño máximo de archivos (imagen, pdf, audio o video)
                        10MB
                      </div>
                    </el-upload>
                  </div>
                </div>
                <el-form-item
                  class="w-100 d-flex flex-column justify-content-start"
                  prop="comment"
                >
                  <label for="comment" class="w-100 el-form-item__label"
                    >Agregar comentario:</label
                  >
                  <!-- comment -->
                  <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="Ingrese el comentario"
                    v-model="ruleForm2.comment"
                  ></el-input>
                </el-form-item>
                <el-row type="flex" justify="end">
                  <el-button
                    class="ml-auto"
                    v-if="!waiting"
                    type="primary"
                    @click="submitForm('ruleForm2')"
                    :disabled="
                      ruleForm2.comment === '' || ruleForm2.comment.length < 10
                    "
                    :loading="loadingUpdateComplaint"
                  >
                    Guardar</el-button
                  >
                  <el-button
                    class="ml-auto"
                    v-if="waiting"
                    type="primary"
                    :loading="true"
                    >Guardando...</el-button
                  >
                </el-row>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <p class="p-3 m-0">
      La información proporcionada será manejada de manera confidencial y
      segura, no se harán público ninguno de los datos entregados.
    </p>
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-4 text-white px-0">
            <a href="mailto:hablemos@valoretico.com" class="mr-2">
              <i class="fas fa-envelope-square fa-1-5x"></i>
              hablemos@valoretico.com
            </a>
          </div>
          <div class="col-sm-12 col-md-4 small">
            Valor Etico, Porque nos importas. Copyright
            {{ moment().format("YYYY") }}
          </div>
          <div class="col-md-4 text-white">
            <a href="#"><i class="fab fa-facebook fa-1-5x"></i></a>
            <a href="#"><i class="fab fa-twitter-square fa-1-5x"></i></a>
          </div>
          <div class="col-md-12">
            <a href="/login">Inicio de sesión</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["recaptcha"],
  data() {
    return {
      recaptchaValue: "",
      waiting: false,
      activeForm: true,
      isSuggestion: false,
      isIncident: true,
      loadingUpdateComplaint: false,
      resume: {
        success: null,
        error: null,
      },
      fileList: [],
      ruleForm: {
        complaint_id: "",
        password: "",
        recaptchaValue: "",
      },
      ruleForm2: {
        comment: "",
        complaint: {
          id: 0,
          state: "",
        },
        fileList: [],
      },
    };
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    verifyrecaptcha(response) {
      this.recaptchaValue = response;
      this.ruleForm.recaptchaValue = response;
    },
    onBeforeUpload(file) {
      console.log("archivo", file);
      const validTypes = [
        "image/jpeg",
        "image/pjg",
        "image/png",
        "application/pdf",
        "audio/mp3",
        "audio/wap",
        "video/mpg",
        "video/mp4",
        "video/mob",
      ];
      let isValid = validTypes.includes(file.type);

      const isLt10M = file.size / 10240 / 10240 < 10;

      if (!isValid) {
        this.$message.error(
          "El archivo subido no cumple con los formatos aceptados"
        );
      }
      if (!isLt10M) {
        this.$message.error("El archivo subido supera el tamaño maximo");
      }
      return isValid && isLt10M;
    },
    handleChange($e, fileList) {
      let isValid = this.onBeforeUpload($e.raw);
      if (isValid) {
        this.ruleForm.fileList.push($e.raw);
      } else {
        fileList.pop();
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `El límite es de ${
          3 - this.resume.complaint.fileList.length
        } archivos. Puedes eliminar algún archivo ya cargado para subir uno nuevo.`
      );
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.waiting = true;

          const url =
            formName === "ruleForm"
              ? `${window.location.origin}/complaint/getrow`
              : `${window.location.origin}/complaint/postdata5`;
          let payload = this.ruleForm;
          let config = { headers: { "Content-Type": "application/json" } };
          // console.log(formName);
          if (formName !== "ruleForm") {
            payload = new FormData();
            // console.log(11111);
            config = { headers: { "Content-Type": "multipart/form-data" } };
            payload.append("form", JSON.stringify(this.ruleForm2));
            payload.append("list", JSON.stringify(this.fileList));
            this.fileList.map((x, index) => {
              payload.append("file" + (index + 1), x);
            });
          }
          axios
            .post(url, payload, config)
            .then((res) => {
              this.waiting = false;
              // console.log(res.data);
              if (res.data.success) {
                this.activeForm = false;
                if (formName === "ruleForm") {
                  this.resume = res.data;
                  this.ruleForm2.complaint.id = res.data.complaint.id;
                  this.ruleForm2.complaint.state =
                    this.resume.complaint.state_logs[
                      this.resume.complaint.state_logs.length - 1
                    ].complaint_state_id;
                } else {
                  this.resume.complaint = res.data.complaint;
                  this.fileList = [];
                  this.ruleForm2 = {
                    comment: "",
                    complaint: {
                      id: this.ruleForm2.complaint.id,
                      state: this.ruleForm2.complaint.state,
                    },
                  };
                }
              } else {
                this.resume = res.data;
                this.$refs[formName].resetFields();
              }
              if (this.resume.complaint.complaints_type_id === null) {
                this.isSuggestion = true;
                this.isIncident = false;
              } else {
                this.isIncident = true;
                this.isSuggestion = false;
              }
            })
            .catch((e) => {
              this.waiting = false;
              this.activeForm = false;
            });
        }
      });
    },
    resetForm() {
      this.waiting = false;
      this.activeForm = true;
      this.resume = {
        success: null,
        error: null,
      };
      this.fileList = [];
      this.$refs["ruleForm"].resetFields();
      this.$refs["ruleForm2"].resetFields();
    },
    reset() {
      this.resume = {
        success: null,
        error: null,
      };
    },
    getLastState() {
      if (this.resume.success) {
        return this.resume.complaint.state_logs[0].complaint_state.name;
      }
      return false;
    },
    getWitnesses() {
      if (this.resume.success) {
        return this.resume.complaint.witnesses ? "Sí" : "No";
      }
      return false;
    },
    getReported() {
      if (this.resume.success) {
        return this.resume.complaint.reported ? "Sí" : "No";
      }
      return false;
    },
    getComplaintType() {
      if (this.resume.success) {
        if (
          this.resume.complaint.complaints_type_id === undefined ||
          this.resume.complaint.complaints_type_id == null
        ) {
          return;
        } else {
          return this.resume.complaint.complaints_type.type
            ? this.resume.complaint.complaints_type.type
            : "Tipos de denuncia sin informar";
        }
      }
      return false;
    },
    getCountry() {
      if (this.resume.success) {
        return this.resume.complaint.country.name
          ? this.resume.complaint.country.name
          : "País sin informar";
      }
      return false;
    },
    getExpectedResult() {
      if (this.resume.success) {
        return this.resume.complaint.expected_result
          ? this.resume.complaint.expected_result
          : "Sin informar";
      }
      return false;
    },
    getSuggestionText() {
      if (this.resume.success) {
        return this.resume.complaint.suggestion_text
          ? this.resume.complaint.suggestion_text
          : "Sin informar";
      }
      return false;
    },
  },
  computed: {
    rules() {
      var checkContent = (rule, value, callback) => {
        if (!value) {
          switch (rule.field) {
            case "complaint_id":
              return callback(new Error("Debe informar código de denuncia"));
            case "password":
              return callback(
                new Error("Debe informar la contraseña entregada")
              );
            default:
              return callback();
          }
        }
        return callback();
      };
      return {
        complaint_id: [
          { required: true, validator: checkContent, trigger: "change" },
        ],
        password: [
          { required: true, validator: checkContent, trigger: "change" },
        ],
        comment: [
          {
            min: 10,
            max: 200,
            message: "Por favor escriba más detalles",
            trigger: "change",
          },
        ],
      };
    },
  },
  validations() {
    return {
      ruleForm: this.rules,
    };
  },
};
</script>
