<template>
  <div class="bg-light mt-3 px-md-4 px-3 py-2">
    <div>
      <el-collapse class="w-100 border-bottom-0 mb-3" v-model="activeNames">
        <el-collapse-item name="1" class="div-filters">
          <template slot="title"
            ><h5 class="pl-3"><i class="el-icon-plus mr-2" />Nuevo Ajuste</h5>
          </template>

          <el-form
            id="local_form"
            ref="ruleForm"
            :rules="rules"
            :model="setting"
            @submit.prevent="submitForm('ruleForm')"
            status-icon
          >
            <div class="row px-3 pt-3 d-flex justify-content-between">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6 px-3">
                    <el-form-item prop="business_name" id="business_name">
                      <!-- nombre -->
                      <label>Nombre Negocio</label>
                      <el-input
                        type="text"
                        v-model="setting.business_name"
                        placeholder="Ingrese el nombre de la empresa"
                      />
                    </el-form-item>
                  </div>
                  <div class="col-md-6 px-3">
                    <el-form-item prop="contact_email" id="contact_email">
                      <!-- Email -->
                      <label>Email</label>
                      <el-input
                        type="text"
                        v-model="setting.contact_email"
                        placeholder="Ingrese el correo de contacto de la empresa"
                      />
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 px-3">
                    <el-form-item
                      :prop="isEdition ? 'language_id' : 'languages'"
                      :id="isEdition ? 'language_id' : 'languages'"
                    >
                      <!-- lenguaje -->
                      <label>Lenguaje</label>
                      <el-select
                        v-if="isEdition"
                        v-model="setting.language_id"
                        class="w-100"
                        filterable
                      >
                        <el-option
                          v-for="language in languages"
                          :key="language.id"
                          :label="language.name"
                          :value="language.id"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-else
                        v-model="setting.languages"
                        class="w-100"
                        multiple
                        filterable
                        @change="
                          (value) => {
                            selectAll(value, 'languages');
                          }
                        "
                      >
                        <el-option label="Todos" value="all"></el-option>
                        <el-option
                          v-for="language in languages"
                          :key="language.id"
                          :label="language.name"
                          :value="language.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6 px-3">
                    <el-form-item
                      :prop="isEdition ? 'country_id' : 'countries'"
                      :id="isEdition ? 'country_id' : 'countries'"
                    >
                      <!-- pais -->
                      <label>País</label>
                      <el-select
                        v-if="isEdition"
                        v-model="setting.country_id"
                        class="w-100"
                        filterable
                      >
                        <el-option
                          v-for="country in countries"
                          :key="country.id"
                          :label="country.name"
                          :value="country.id"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-else
                        v-model="setting.countries"
                        class="w-100"
                        multiple
                        filterable
                        @change="
                          (value) => {
                            selectAll(value, 'countries');
                          }
                        "
                      >
                        <el-option label="Todos" value="all"></el-option>
                        <el-option
                          v-for="country in countries"
                          :key="country.id"
                          :label="country.name"
                          :value="country.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-md-12 col-9">
                    <el-form-item>
                      <!-- agregar label -->
                      <label v-show="!isEdition" class="pr-2 mb-0"
                        >Permite linea ética:</label
                      >
                      <!-- anonimo -->
                      <el-checkbox
                        class="mr-0"
                        v-show="!isEdition"
                        v-model="setting.allow_anonymous"
                        label="Anónima"
                        border
                      ></el-checkbox>

                      <!-- rapido -->
                      <el-checkbox
                        class="m-0"
                        v-show="!isEdition"
                        v-model="setting.allow_quick"
                        label="Rápida"
                        border
                      ></el-checkbox>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pt-2 pr-3 text-center">
                <label>Logo Negocio</label>
                <el-form-item prop="fileList" id="fileList">
                  <!-- logo -->
                  <div
                    class="d-flex justify-content-center"
                    v-if="truthty(image)"
                  >
                    <img
                      :src="image"
                      alt="Logo"
                      style="max-height: 300px; width: 200px"
                    />
                  </div>

                  <el-upload
                    class="upload-demo text-center"
                    action="#"
                    :on-change="handleChange"
                    :on-remove="handleRemove"
                    :auto-upload="false"
                    :limit="1"
                    :on-exceed="handleExceed"
                    :file-list="setting.fileList"
                    accept="image/*"
                  >
                    <el-button class="mt-2" size="small" type="primary"
                      >Click para subir perfil</el-button
                    >
                    <div slot="tip" class="el-upload__tip">
                      Tamaño máximo de la imagen 10MB
                    </div>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
            <div class="row px-3 pb-4">
              <div class="col text-center">
                <button
                  @click.prevent="submitForm('ruleForm')"
                  class="btn btn-info text-white mx-auto"
                  :disabled="
                    ((setting.business_name === '' ||
                      setting.business_name < 2) &&
                      (setting.contact_email === '' ||
                        setting.contact_email < 5)) ||
                    waiting
                  "
                >
                  {{ isEdition ? "Actualizar" : "Guardar" }}
                </button>
              </div>
            </div>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </div>

    <!-- ------------------------------------------------------------------------------------------------------------------- -->
    <div class="row w-100 mx-0">
      <el-table
        :data="settings"
        style="width: 100%; height: 100%"
        max-height="350"
        v-loading="waiting"
      >
        <el-table-column
          prop="business_name"
          label="Nombre Negocio"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="contact_email" label="Email" min-width="150">
        </el-table-column>
        <el-table-column
          width="150"
          prop="country.name"
          label="País"
          :filters="countryFilters()"
          :filter-method="filterCountries"
        >
        </el-table-column>
        <el-table-column
          min-width="120"
          label="Lenguaje"
          prop="language.name"
          :filters="languageFilters()"
          :filter-method="filterLanguage"
        >
        </el-table-column>

        <el-table-column
          label="Anónima"
          min-width="180"
          :filters="[
            { text: 'Activo', value: 1 },
            { text: 'Inactivo', value: 0 },
          ]"
          :filter-method="filterAnonymous"
        >
          <template slot-scope="scope">
            <el-switch
              class="align-items-center switch-compact switch"
              @change="editSettingAnonymous(scope.$index)"
              :value="scope.row.allow_anonymous == 1"
              active-text="Activo"
              inactive-text="Inactivo"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="Rápida"
          min-width="180"
          :filters="[
            { text: 'Activo', value: 1 },
            { text: 'Inactivo', value: 0 },
          ]"
          :filter-method="filterQuick"
        >
          <template slot-scope="scope">
            <el-switch
              class="align-items-center switch-compact switch"
              @change="editSettingQuick(scope.$index)"
              :value="scope.row.allow_quick == 1"
              active-text="Activo"
              inactive-text="Inactivo"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="Estado"
          min-width="180"
          :filters="[
            { text: 'Activo', value: 1 },
            { text: 'Inactivo', value: 0 },
          ]"
          :filter-method="filterState"
        >
          <template slot-scope="scope">
            <el-switch
              class="align-items-center switch-compact switch"
              @change="editSettingState(scope.$index)"
              :value="scope.row.active == 1"
              active-text="Activo"
              inactive-text="Inactivo"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          class-name="item-center"
          fixed="right"
          min-width="100"
          label="Acciones"
        >
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="editsetting(scope.$index)"
              :type="
                isEdition && scope.$index == editedsettingIndex
                  ? 'warning'
                  : 'primary'
              "
              :icon="
                isEdition && scope.$index == editedsettingIndex
                  ? 'el-icon-close'
                  : 'el-icon-edit'
              "
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["countries", "settings", "languages"],
  data() {
    let checkMailFormat = (rule, value, callback) => {
      if (!this.isEmailValid(value)) {
        return callback(new Error("Formato de email incorrecto"));
      }
      return callback();
    };
    let checkCountry = (rule, value, callback) => {
      if (!this.isEdition && !this.truthty(value)) {
        return callback(new Error("Debe seleccionar al menos 1 país"));
      }
      return callback();
    };
    let checkLanguege = (rule, value, callback) => {
      if (!this.isEdition && !this.truthty(value)) {
        return callback(new Error("Debe seleccionar al menos 1 lenguaje"));
      }
      return callback();
    };
    return {
      activeNames: [],
      setting: {
        business_name: "",
        contact_email: undefined,
        countries: [],
        languages: [],
        allow_anonymous: false,
        allow_quick: false,
        file: [],
        fileList: [],
        active: true,
      },
      showState: false,
      editedsettingId: undefined,
      isEdition: false,
      editedsettingIndex: undefined,
      image: null,
      rules: {
        business_name: [
          {
            required: true,
            message: "Debe escribir el nombre de la empresa",
            trigger: ["change", "blur"],
          },
        ],
        contact_email: [
          {
            required: true,
            message: "Debe escribir el email de la empresa",
            trigger: ["change", "blur"],
          },
          { validator: checkMailFormat, trigger: "blur" },
        ],
        countries: [{ validator: checkCountry, trigger: ["change", "blur"] }],
        languages: [{ validator: checkLanguege, trigger: ["change", "blur"] }],
        fileList: [
          {
            required: true,
            message: "Debe agregar la imagen del logo",
            trigger: ["change", "blur"],
          },
        ],
      },
      waiting: false,
    };
  },
  methods: {
    submitForm(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid, rules) => {
        console.log(valid, rules);
        if (valid) {
          this.submit();
        } else {
          this.goToError(rules);
        }
      });
    },
    filterAnonymous(value, row) {
      return row.allow_anonymous == value;
    },
    filterQuick(value, row) {
      return row.allow_quick == value;
    },
    editSettingAnonymous(settingIndex) {
      const setting = this.settings[settingIndex];
      const {
        id,
        business_name,
        contact_email,
        country_id,
        language_id,
        allow_anonymous,
        allow_quick,
        logo,
        active,
      } = setting;
      this.isEdition = true;
      this.editedsettingId = id;
      this.editedsettingIndex = settingIndex;
      const data = {
        business_name: business_name,
        contact_email: contact_email,
        country_id: country_id,
        language_id: language_id,
        allow_anonymous: allow_anonymous == 1 ? 0 : 1,
        allow_quick: allow_quick,
        logo: logo,
        active: active,
      };
      this.submitEdit(data);
    },
    filterState(value, row) {
      return row.active == value;
    },
    editSettingState(settingIndex) {
      const setting = this.settings[settingIndex];
      const {
        id,
        business_name,
        contact_email,
        country_id,
        language_id,
        allow_anonymous,
        allow_quick,
        logo,
        active,
      } = setting;
      this.isEdition = true;
      this.editedsettingId = id;
      this.editedsettingIndex = settingIndex;
      const data = {
        business_name: business_name,
        contact_email: contact_email,
        country_id: country_id,
        language_id: language_id,
        allow_anonymous: allow_anonymous,
        allow_quick: allow_quick,
        logo: logo,
        active: active == 1 ? 0 : 1,
      };
      this.submitEdit(data);
    },
    editSettingQuick(settingIndex) {
      const setting = this.settings[settingIndex];
      const {
        id,
        business_name,
        contact_email,
        country_id,
        language_id,
        allow_anonymous,
        allow_quick,
        logo,
        active,
      } = setting;
      this.isEdition = true;
      this.editedsettingId = id;
      this.editedsettingIndex = settingIndex;
      const data = {
        business_name: business_name,
        contact_email: contact_email,
        country_id: country_id,
        language_id: language_id,
        allow_anonymous: allow_anonymous,
        allow_quick: allow_quick == 1 ? 0 : 1,
        logo: logo,
        active: active,
      };
      this.submitEdit(data);
    },
    handleChange($e) {
      this.image = URL.createObjectURL($e.raw);
      this.setting.fileList.push($e.raw);
      this.$refs["ruleForm"].validateField("fileList");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.setting.fileList = fileList;
      this.image = null;
      this.$refs["ruleForm"].validateField("fileList");
    },
    handleExceed(files, fileList) {
      this.$message.warning(`Solo puedes subir 1 logo.`);
    },
    countryFilters() {
      const filters = this.countries.map((country) => {
        return {
          text: country.name,
          value: country.id,
        };
      });
      return filters;
    },
    filterCountries(value, row) {
      return row.country_id == value;
    },
    languageFilters() {
      const filters = this.languages.map((language) => {
        return {
          text: language.name,
          value: language.id,
        };
      });
      return filters;
    },
    filterLanguage(value, row) {
      return row.language_id == value;
    },
    clearForm() {
      this.setting = {
        business_name: "",
        contact_email: undefined,
        country_id: undefined,
        countries: [],
        language_id: undefined,
        languages: [],
        allow_anonymous: false,
        allow_quick: false,
        fileList: [],
        file: [],
        active: undefined,
      };
      this.image = null;
      this.$refs["ruleForm"].resetFields();
    },
    clearEditData() {
      this.isEdition = false;
      this.editedsettingIndex = undefined;
      this.editedsettingId = undefined;
    },
    editsetting(settingIndex) {
      if (settingIndex == this.editedsettingIndex) {
        this.clearForm();
        this.clearEditData();
        return;
      }
      const setting = this.settings[settingIndex];
      const {
        id,
        business_name,
        contact_email,
        country_id,
        language_id,
        allow_anonymous,
        allow_quick,
        logo,
        urlLogo,
        active,
      } = setting;
      this.isEdition = true;
      this.editedsettingId = id;
      this.editedsettingIndex = settingIndex;
      console.log(setting);
      this.setting = {
        business_name,
        contact_email,
        country_id,
        language_id,
        allow_anonymous,
        allow_quick,
        logo,
        fileList: [{ name: "Logo", url: urlLogo }],
        active,
      };
      this.image = urlLogo;
      let element = document.getElementById("local_form");
      if (this.truthty(element)) element.scrollIntoView({ behavior: "smooth" });
      this.$refs["ruleForm"].resetFields();
    },
    showConfirmModal(settingIndex) {
      this.$confirm(
        "¿Está seguro/a que desea eliminar el ajuste? Esta acción es irreversible.",
        "Atención",
        {
          confirmButtonText: "Si, borrar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      ).then(() => {
        const setting = this.settings[settingIndex];
        const { id } = setting;
        this.editedsettingId = id;
        this.editedsettingIndex = settingIndex;
        this.submit();
      });
    },
    submit() {
      if (this.isEdition) {
        const {
          business_name,
          contact_email,
          country_id,
          language_id,
          allow_anonymous,
          allow_quick,
          logo,
          file,
          active,
        } = this.setting;
        const data = {
          business_name: business_name,
          contact_email: contact_email,
          country_id: country_id,
          language_id: language_id,
          allow_anonymous: allow_anonymous,
          allow_quick: allow_quick,
          logo: logo,
          active: active,
        };
        this.submitEdit(data);
      } else {
        this.submitCreate();
      }
    },
    submitCreate() {
      this.waiting = true;
      const url = `${window.location.origin}/settings/create`;
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      let formData = new FormData();
      formData.append("payload", JSON.stringify(this.setting));
      this.setting.fileList.map((x) => {
        formData.append("file", x);
      });
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log(res.data.setting);
          this.settings = res.data.todosSetting;
          this.clearForm();
          this.waiting = false;
          this.$message({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          this.waiting = false;

          this.$message({
            message: error.response.data.message,
            type: "error",
            duration: 2000,
          });
        });
    },
    submitEdit(settingData) {
      this.waiting = true;
      const url = `${window.location.origin}/settings/update/${this.editedsettingId}`;
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      let formData = new FormData();
      formData.append("payload", JSON.stringify(settingData));
      axios
        .post(url, formData, config)
        .then((res) => {
          this.waiting = false;
          const index = this.editedsettingIndex;
          console.log(this.setting, index);
          console.log(res.data.setting);
          this.settings[index] = res.data.setting;
          this.clearForm();
          this.clearEditData();
          this.$message({
            message: res.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          this.waiting = false;
          console.error(error);
          this.$message.error("Ha ocurrido un error al editar el ajuste");
        });
    },
    selectAll(values, type) {
      let addAll = values.includes("all");
      if (addAll) {
        this.setting[type] = this.$props[type].map((x) => x.id);
      }
    },
  },
  mounted() {
    console.log(this.settings);
    if (this.checkMobile() == false) {
      this.activeNames = ["1"];
    }
  },
};
</script>
